import { useDispatch, useSelector } from "react-redux";
import EditCatagory from "./components/pages/EditCatagory";
import React from "react";
import AddNewCatagory from "./components/pages/AddNewCatagory";
import AdddNewItem from "./components/pages/AddNewItem";
import AddNewSub from "./components/pages/AddNewSub";
import EditItem from "./components/pages/EditItem";
import EditSub from "./components/pages/EditSub";
import { useTranslation } from "react-i18next";
import { closeEdit } from "../../redux/menuFunctions/menuFunctionsSlicer";

export default function DataEditContainer() {
  let { selectType, view } = useSelector((store) => store.menuFunction);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  function handleRender() {
    switch (selectType) {
      case "newCatagory":
        return <AddNewCatagory />;
      case "editCatagory":
        return <EditCatagory />;
      case "newItem":
        return <AdddNewItem />;
      case "editItem":
        return <EditItem />;
      case "newSubItem":
        return <AddNewSub />;
      case "editSub":
        return <EditSub />;
      default:
        return null;
    }
  }

  return view ? (
    <div className="position-fixed end-0 start-0 bottom-0 h-100 bg-dark bg-opacity-50 z-3 d-flex justify-content-end">
      <div className="h-100 bg-light custom-edit-slide d-flex flex-column">
        <div className="bg-white p-3 d-flex align-items-center gap-2">
          <button
            className="btn-close"
            onClick={() => dispatch(closeEdit())}
          ></button>
          <h4 className="mb-0">{t(selectType)}</h4>
        </div>
        <div className="overflow-y-scroll h-100">
          <div>{handleRender()}</div>
        </div>
      </div>
    </div>
  ) : null;
}
