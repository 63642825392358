import axios from "axios";

export default async function checkPaymentStatus(paymentID, token) {
  var data = "";

  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url:
      "https://fib.stage.fib.iq/protected/v1/payments/" + paymentID + "/status",
    headers: { Authorization: "Bearer " + token },
    data: data,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch {
    throw new Error("Failed to get Statuss");
  }
}
