import { Fragment, useEffect, useRef, useState } from "react";
import MenuEditItem from "./menuEditItem";
import GrabberComp from "./grabberComp";
import DisplayChildrenButton from "./displayChildrenButton";
import ActivationSwitch from "./activationSwitch";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import elementMoveLogic from "./elementMoveLogic";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import AddNewElementComb from "./addNewElementComb";
import handleElemEdit from "./handleElemEdit";

export default function MenuEditSubCatagory({
  catId,
  subData,
  catIndex,
  itemIndex,
  lang,
}) {
  const [childrenDisplay, setChildrenDisplay] = useState(false);
  const { id, enable, name, sub } = subData;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const disRef = useRef();

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div className="my-2">
      <div
        className="btn btn-light bg-white d-flex p-0"
        ref={setNodeRef}
        style={{ ...style, touchAction: "none" }}
      >
        <GrabberComp attributes={attributes} listeners={listeners} />
        <div
          className="row  w-100 align-items-center py-2 pe-3"
          onClick={(elem) =>
            handleElemEdit({
              elem: elem,
              elemDisref: disRef,
              elemID: id,
              elemData: subData,
              elemType: "item",
            })
          }
        >
          <div className="col d-flex">
            <span>{name[lang]}</span>
          </div>

          <div
            className="col d-flex justify-content-end align-items-center gap-4"
            ref={disRef}
          >
            <ActivationSwitch elemId={id} elemCond={enable} elemType={"item"} />
            <DisplayChildrenButton
              childrenDisplay={childrenDisplay}
              setChildrenDisplay={setChildrenDisplay}
            />
          </div>
        </div>
      </div>
      <SubChildren
        catId={catId}
        id={id}
        childrenDisplay={childrenDisplay}
        sub={sub}
        lang={lang}
        catIndex={catIndex}
        itemIndex={itemIndex}
      />
    </div>
  );
}

function SubChildren({
  catId,
  id,
  childrenDisplay,
  sub,
  lang,
  catIndex,
  itemIndex,
}) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 12,
      },
    })
  );

  return (
    childrenDisplay && (
      <div className="ms-4">
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={(event) =>
            elementMoveLogic({
              event: event,
              items: sub,
              catIndex: catIndex,
              itemIndex: itemIndex,
              type: "sub",
              parentId: id,
            })
          }
          sensors={sensors}
          modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
        >
          <SortableContext items={sub} strategy={verticalListSortingStrategy}>
            {sub.map((elem, index) => {
              return (
                <MenuEditItem
                  key={elem.id + "subChildItem" + index}
                  catId={catId}
                  elemData={elem}
                  lang={lang}
                  elemType={"sub"}
                />
              );
            })}
          </SortableContext>
        </DndContext>
        <AddNewElementComb id={id} type={"sub"} />
      </div>
    )
  );
}
