import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import editItem from "../images/editItem.svg";
import gear from "../images/gear.svg";
import CreateMenu from "../components/menuInfo/createMenu";
import EditMenuInfo from "../components/menuInfo/editMenuInfo";
import React, { useEffect, useRef, useState } from "react";
import { getMenus } from "../redux/getMenus/getMenus.js";
import { useDispatch, useSelector } from "react-redux";
import { isActiveApi, TabletUserRegistration } from "../services/menuServices";

import { useTranslation } from "react-i18next";
import CreateQr from "../components/menuInfo/createQr.js";
import {
  setScrollOff,
  setScrollOn,
} from "../redux/screenScroll/screenScroolSlice.js";
import PageHeader from "./pageHeader.js";
// import { getUserMenus } from "../services/menuServices";

const cookies = new Cookies();

export default function Home() {
  const { t } = useTranslation();

  const [makeMenu, setMakeMenu] = useState(false);

  const dispatch = useDispatch();

  const userId = cookies.get("userID");

  const { allMenu, allMenuSuccess } = useSelector((store) => store.userMenus);

  useEffect(() => {
    if (makeMenu === true) {
      dispatch(setScrollOff());
    } else {
      dispatch(setScrollOn());
    }
  }, [makeMenu]);

  useEffect(() => {
    dispatch(getMenus(userId));
  }, []);

  return (
    <div className="bg-body-secondary">
      <MenuHeader setMakeMenu={setMakeMenu} t={t} />

      <div className="container py-4 ">
        {allMenuSuccess ? (
          allMenu.map((menu) => {
            return (
              <MenuInfoContainer
                key={menu.id}
                menuID={menu.id}
                menuInfo={menu}
                title={menu.title}
                menuActivity={menu.active}
                t={t}
              />
            );
          })
        ) : (
          <LoadingScreen />
        )}
      </div>

      <CreateMenu makeMenu={makeMenu} setMakeMenu={setMakeMenu} />
    </div>
  );
}

function MenuHeader({ setMakeMenu, t }) {
  const userRole = cookies.get("userRole");

  return (
    <React.Fragment>
      <PageHeader />
      <div className="bg-light border-bottom  d-block">
        <div className="container py-2 d-flex justify-content-between">
          <h4 className="">{t("menuManage")}</h4>

          {(userRole === "admin" || userRole === "manager") && (
            <button
              className="btn btn-sm btn-primary my-auto"
              style={{ width: "max-content" }}
              onClick={() => setMakeMenu(true)}
            >
              {t("createaMenu")}
            </button>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

function MenuInfoContainer({ menuID, menuInfo, title, menuActivity, t }) {
  const [isActive, setIsActive] = useState(menuActivity);
  const [showEditMenu, setShowEditMenu] = useState(false);

  const [showQrMenu, setShowQrMenu] = useState(false);

  const [viewCreateTab, setViewCreateTab] = useState(false);

  const [toolTipView, setToolTipView] = useState(false);
  const toolTipRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (showEditMenu === true || showQrMenu === true) {
      dispatch(setScrollOff());
    } else {
      dispatch(setScrollOn());
    }
  }, [showEditMenu, showQrMenu]);

  function handleOnSwtich(e) {
    const token = cookies.get("token");

    setIsActive(e.target.checked);

    isActiveApi(menuID, e.target.checked, token);
  }

  return (
    <React.Fragment>
      <div
        className="bg-light border-opacity-10 mx-4 mb-3 rounded-2 d-flex justify-content-between"
        key={title}
      >
        <div className="w-100 d-flex  flex-sm-row flex-column justify-content-between p-3 gap-2 flex-wrap">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="mb-0 position-relative">
              {title}
              {isActive ? (
                <span className="position-absolute top-0 start-100 translate-middle bg-success border border-light rounded-circle p-1">
                  <span className="visually-hidden">Menu is On</span>
                </span>
              ) : (
                <span className="position-absolute top-0 start-100 translate-middle bg-danger border border-light rounded-circle p-1">
                  <span className="visually-hidden">Menu Is Off</span>
                </span>
              )}
            </h4>
          </div>
          <hr className="my-2 mx-2 d-sm-none d-block" />
          <div className="d-flex align-items-center justify-content-sm-end justify-content-between gap-2">
            <Link
              to={`edit/${title}`}
              className="btn btn-primary btn-sm d-flex gap-1"
            >
              <img src={editItem} style={{ width: "20px" }} alt="editItem" />
              <span>{t("editMenu")}</span>
            </Link>
            <div ref={toolTipRef} className="position-relative">
              <button
                onClick={() => setToolTipView((previous) => !previous)}
                className="btn btn-sm"
              >
                <img src={gear} alt="o" style={{ width: "20px" }} />
              </button>
              <ToolTip
                title={menuInfo.title}
                toolTipRef={toolTipRef}
                toolTipView={toolTipView}
                setToolTipView={setToolTipView}
                setShowEditMenu={setShowEditMenu}
                t={t}
                setShowQrMenu={setShowQrMenu}
                setViewCreateTab={setViewCreateTab}
              />
            </div>
          </div>
        </div>
      </div>
      <EditMenuInfo
        menuInfo={menuInfo}
        showEditMenu={showEditMenu}
        setShowEditMenu={setShowEditMenu}
        isActive={isActive}
        handleOnSwtich={handleOnSwtich}
      />

      <CreateTabletUser
        menuID={menuID}
        viewCreateTab={viewCreateTab}
        setViewCreateTab={setViewCreateTab}
      />

      {showQrMenu ? (
        <CreateQr
          title={title}
          showQrMenu={showQrMenu}
          setShowQrMenu={setShowQrMenu}
        />
      ) : null}
    </React.Fragment>
  );
}

function ToolTip({
  title,
  toolTipRef,
  toolTipView,
  setToolTipView,
  setShowEditMenu,
  t,
  setShowQrMenu,
  setViewCreateTab,
}) {
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [toolTipRef]);

  function handleClickOutside(event) {
    if (toolTipRef.current && !toolTipRef.current.contains(event.target)) {
      setToolTipView(false);
    }
  }

  return toolTipView ? (
    <div className="position-absolute bg-white border border-dark-subtle rounded-2 z-3 end-0 overflow-hidden">
      <button
        className="btn btn-sm btn-light text-nowrap w-100 rounded-0"
        onClick={() => {
          setShowEditMenu(true);
          setToolTipView(false);
        }}
      >
        {t("editMenu")}
      </button>

      <button
        className="btn btn-sm btn-light text-nowrap w-100 rounded-0"
        onClick={() => {
          setToolTipView(false);
          setShowQrMenu(true);
        }}
      >
        {t("genQR")}
      </button>

      <button
        className="btn btn-sm btn-light text-nowrap w-100 rounded-0"
        onClick={() => {
          setToolTipView(false);
          setViewCreateTab(true);
        }}
      >
        {
          // t("genQR")
          "Create Tablet User"
        }
      </button>

      <Link
        className="btn btn-sm btn-light text-nowrap w-100 rounded-0"
        to={"payment/" + title}
      >
        {t("toPayment")}
      </Link>

      <Link
        className="btn btn-sm btn-light text-nowrap w-100 rounded-0"
        target="_blank"
        to={"https://menu.mubatech.com/" + title}
      >
        {t("toMenu")}
      </Link>
    </div>
  ) : null;
}

function LoadingScreen() {
  return [[], [], []].map((e, i) => {
    return (
      <div
        key={"temp" + i}
        className="border-opacity-10 mx-4 mb-4 rounded-1 d-flex justify-content-between custom-gradient-background-animated"
      >
        <div
          key={"temp" + i + "child"}
          className="w-100 d-flex justify-content-between p-3 gap-2 flex-wrap"
        >
          <div
            className="custom-gradient-background-animated rounded-2"
            style={{ height: "34px", width: "75px" }}
          ></div>
          <div
            className="custom-gradient-background-animated rounded-2"
            style={{
              height: "34px",
              maxWidth: "219px",
              width: "50%",
            }}
          ></div>
        </div>
      </div>
    );
  });
}

function CreateTabletUser({ viewCreateTab, setViewCreateTab, menuID }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState([]);
  const token = cookies.get("token");

  async function handleCreateUser() {
    const requiredFields = username && password;
    let errorArr = [];

    if (!username) {
      errorArr.push("Username is required");
    }
    if (!password) {
      errorArr.push("Password is required");
    }

    if (requiredFields) {
      let formData = new FormData();

      const validData = {
        username: username,
        password: password,
        menuID: menuID,
      };

      Object.keys(validData).forEach((objName) => {
        formData.append(objName.toString(), validData[objName]);
      });

      const res = await TabletUserRegistration(formData, token);

      if (res.success === false) errorArr.push(res.message);
      if (res.success === true) reset();
    }

    setErrorMessage(errorArr);
  }

  function reset() {
    setUsername("");
    setPassword("");
    setViewCreateTab(false);
  }

  if (viewCreateTab === true)
    return (
      <div className="position-fixed bg-dark bg-opacity-25 top-0 bottom-0 start-0 end-0 d-flex align-items-center">
        <div className="m-auto card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h6 className="my-2">Create Tablet Account</h6>
            <button className="ms-4 btn btn-close" onClick={() => reset()} />
          </div>
          <div className="card-body">
            <label className="form-label"> Username </label>
            <input
              type="text"
              className="form-control form-control-sm"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <hr />
            <label className="form-label"> Password </label>
            <input
              type="text"
              className="form-control form-control-sm"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {errorMessage.length > 0 && (
              <div className="mt-3">
                {errorMessage.map((e) => (
                  <div className="my-1 badge bg-danger text-center d-block">
                    {e}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="card-footer">
            <button
              className="btn btn-sm btn-primary px-3"
              onClick={() => handleCreateUser()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
}
