import { arrayMove } from "@dnd-kit/sortable";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const token = cookies.get("token");

export const fetchMenu = createAsyncThunk("menu", async (title) => {
  let url = `https://menuapi.mubatech.com/api/v1/getMenuItemsForAdmin/${title}`;
  let data = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return data.data;
});
export const menuSlice = createSlice({
  name: "menu",
  initialState: {
    menu: [],
    status: false,
    success: false,
  },

  reducers: {
    reseatMenuData: (state) => {
      state.menu = [];
      state.status = false;
      state.success = false;
    },

    reArangeCat: (state, action) => {
      state.menu = {
        ...state.menu,
        data: arrayMove(
          state.menu.data,
          action.payload.activeIndex,
          action.payload.overIndex
        ),
      };
    },

    reArangeItem: (state, action) => {
      state.menu = {
        ...state.menu,
        data: [
          ...state.menu.data.slice(0, action.payload.catIndex),
          {
            ...state.menu.data[action.payload.catIndex],
            items: arrayMove(
              state.menu.data[action.payload.catIndex].items,
              action.payload.activeIndex,
              action.payload.overIndex
            ),
          },
          ...state.menu.data.slice(action.payload.catIndex + 1),
        ],
      };
    },
    reArangeSub: (state, action) => {
      state.menu = {
        ...state.menu,
        data: [
          ...state.menu.data.slice(0, action.payload.catIndex),
          {
            ...state.menu.data[action.payload.catIndex],
            items: [
              ...state.menu.data[action.payload.catIndex].items.slice(
                0,
                action.payload.itemIndex
              ),
              {
                ...state.menu.data[action.payload.catIndex].items[
                  action.payload.itemIndex
                ],
                sub: arrayMove(
                  state.menu.data[action.payload.catIndex].items[
                    action.payload.itemIndex
                  ].sub,
                  action.payload.activeIndex,
                  action.payload.overIndex
                ),
              },

              ...state.menu.data[action.payload.catIndex].items.slice(
                action.payload.itemIndex + 1
              ),
            ],
          },
          ...state.menu.data.slice(action.payload.catIndex + 1),
        ],
      };
    },
  },
  extraReducers: {
    [fetchMenu.pending]: (state, action) => {
      state.status = true;
    },
    [fetchMenu.fulfilled]: (state, action) => {
      state.status = false;
      state.menu = action.payload;
      state.success = true;
    },
  },
});
export default menuSlice.reducers;

export const { reseatMenuData, reArangeCat, reArangeItem, reArangeSub } =
  menuSlice.actions;
