import axios from "axios";

export async function login(loginInfo) {
  try {
    let url = "https://menuapi.mubatech.com/api/admin/v1/login";
    let data = await axios.post(url, loginInfo);

    return data.data;
  } catch (error) {
    console.log(error);
  }
}
