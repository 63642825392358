import { viewEdit } from "../../../redux/menuFunctions/menuFunctionsSlicer";
import { store } from "../../../redux/store";

export default function handleElemEdit({
  elem,
  elemDisref,
  elemType,
  elemID,
  elemData,
}) {
  const elemTypeIndex = {
    item: "editItem",
    sub: "editSub",
    cat: "editCatagory",
  };

  if (
    elem.target !== elemDisref.current &&
    elemDisref.current.contains(elem.target)
  ) {
  } else {
    store.dispatch(
      viewEdit({
        selectType: elemTypeIndex[elemType],
        id: elemID,
        allData: elemData,
      })
    );
  }
}
