import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import mubaLogoLight from "../images/mubaLogoLight.jpg";

const cookies = new Cookies();

export default function PageHeader() {
  const { t } = useTranslation();
  const headerToolTipRef = useRef(null);
  const [headerToolTipView, setHeaderToolTipView] = useState(false);

  return (
    <div>
      <header className=" py-2 bg-body border-bottom">
        <div className="container d-flex justify-content-between">
          <div
            // to="/"
            className="d-flex gap-1 text-center align-items-center link-dark text-decoration-none"
          >
            <img
              src={mubaLogoLight}
              alt="mubaLogo"
              style={{ height: "30px" }}
            />
            <h3 className="mb-0 fw-bold">MUBA MENU</h3>
          </div>
          <div className="d-flex gap-4 ">
            <div className="position-relative my-auto" ref={headerToolTipRef}>
              <button
                className="btn btn-primary my-auto"
                onClick={() => setHeaderToolTipView(!headerToolTipView)}
              >
                {t("settings")}
              </button>
              <HeaderToolTip
                headerToolTipRef={headerToolTipRef}
                setHeaderToolTipView={setHeaderToolTipView}
                headerToolTipView={headerToolTipView}
                t={t}
              />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

function HeaderToolTip({
  headerToolTipRef,
  setHeaderToolTipView,
  headerToolTipView,

  t,
}) {
  const { i18n } = useTranslation();
  const [viewLangList, setViewLangList] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [headerToolTipRef]);

  function handleClickOutside(event) {
    if (
      headerToolTipRef.current &&
      !headerToolTipRef.current.contains(event.target)
    ) {
      setHeaderToolTipView(false);
      setViewLangList(false);
    }
  }

  async function logOut() {
    await cookies.remove("isLogined", { path: "/" });
    await cookies.remove("token", { path: "/" });
    await cookies.remove("userID", { path: "/" });
    await cookies.remove("userRole", { path: "/" });

    await sessionStorage.removeItem("isLogined");
    await sessionStorage.removeItem("token");
    await sessionStorage.removeItem("userID");

    await navigate("/");
    await window.location.reload();
  }

  const languageList = {
    en: { nickName: "English" },
    ar: { nickName: "Arabic" },
  };

  return headerToolTipView ? (
    <div className="position-absolute z-3 end-50 mt-1 d-flex gap-1">
      {viewLangList ? (
        <div
          className="bg-light border border-dark-subtle rounded-2 py-2"
          style={{ height: "min-content" }}
        >
          {Object.keys(languageList).map((indLang) => (
            <button
              className={`btn btn-light btn-sm w-100 px-2 rounded-0 fw-semibold ${
                indLang === i18n.resolvedLanguage
                  ? "text-primary"
                  : "text-dark-emphasis"
              }`}
              onClick={() => {
                i18n.changeLanguage(indLang);
                setHeaderToolTipView(false);
                setViewLangList(false);
              }}
            >
              {t(languageList[indLang].nickName)}
            </button>
          ))}
        </div>
      ) : null}
      <div className="bg-light border border-dark-subtle rounded-2 py-2">
        <button
          className="btn btn-sm btn-light text-nowrap w-100 rounded-0"
          onClick={() => setViewLangList(!viewLangList)}
        >
          {t("changeLang")}
        </button>

        <Link
          to="/user"
          className="btn btn-sm btn-light text-nowrap w-100 rounded-0"
        >
          {t("userData")}
        </Link>
        <button
          className="btn btn-sm btn-light text-danger text-bold text-nowrap w-100 rounded-0"
          onClick={() => logOut()}
          type="button"
          name=""
          id=""
        >
          {t("logOut")}
        </button>
      </div>
    </div>
  ) : null;
}
