import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { viewEdit } from "../../../redux/menuFunctions/menuFunctionsSlicer";

export default function AddNewElementComb({ id, type }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // type = cat || item || sub

  let newItemData = {};

  function handleAddNewElem() {
    if (type === "cat")
      newItemData = {
        selectType: "newCatagory",
      };
    if (type === "item")
      newItemData = {
        selectType: "newItem",
        id: id,
      };
    if (type === "sub")
      newItemData = {
        selectType: "newSubItem",
        id: id,
        allData: "",
      };

    dispatch(viewEdit(newItemData));
  }

  return (
    <div
      className="bg-white px-3 py-2 rounded my-2 d-flex align-items-center btn btn-light"
      onClick={handleAddNewElem}
    >
      <span className="fs-3 p-0 m-0 fw-bold">
        <i className="bi bi-plus"></i>
      </span>

      <span className="ms-2">
        {type === "cat" && t("addCat")}
        {type === "item" && t("addItem")}
        {type === "sub" && t("addSubItem")}
      </span>
    </div>
  );
}
