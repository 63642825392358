export default function DisplayChildrenButton({
  childrenDisplay,
  setChildrenDisplay,
}) {
  return (
    <button
      className="btn bg-white border-0"
      onClick={() => {
        setChildrenDisplay(!childrenDisplay);
      }}
    >
      <span
        className="fs-4 text-secondary d-block"
        style={{
          transition: "0.25s",
          transform: childrenDisplay ? "rotate(180deg)" : "",
        }}
      >
        <i className="bi bi-chevron-down "></i>
      </span>
    </button>
  );
}
