import { Fragment, useEffect, useRef, useState } from "react";
import MenuEditItem from "./menuEditItem";
import GrabberComp from "./grabberComp";
import ActivationSwitch from "./activationSwitch";
import MenuEditSubCatagory from "./menuEditSubCatagory";
import DisplayChildrenButton from "./displayChildrenButton";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import elementMoveLogic from "./elementMoveLogic";
import AddNewElementComb from "./addNewElementComb";
import handleElemEdit from "./handleElemEdit";

export default function MenuEditCatagory({ catIndex, allData, lang }) {
  const [childrenDisplay, setChildrenDisplay] = useState(false);
  const { name, active, items, id } = allData;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const disRef = useRef();
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div className="my-2">
      <div
        className="bg-white rounded p-0 btn btn-light d-flex w-100"
        ref={setNodeRef}
        style={{ ...style, touchAction: "none" }}
        onClick={(elem) =>
          handleElemEdit({
            elem: elem,
            elemDisref: disRef,
            elemID: id,
            elemData: allData,
            elemType: "cat",
          })
        }
      >
        <GrabberComp attributes={attributes} listeners={listeners} />

        <section className="row pe-3 w-100 py-2">
          <div className="col-6 d-flex gap-3 align-items-center">
            <img
              className="rounded bg-light"
              src={`https://menuapi.mubatech.com/images/categories/${id}.png`}
              width={40}
              height={40}
              alt="img"
            />
            <span className="ms-2">{name[lang]}</span>
          </div>
          <div
            className="col-6 d-flex justify-content-end align-items-center gap-4"
            ref={disRef}
          >
            <ActivationSwitch elemId={id} elemCond={active} elemType={"cat"} />

            <DisplayChildrenButton
              childrenDisplay={childrenDisplay}
              setChildrenDisplay={setChildrenDisplay}
            />
          </div>
        </section>
      </div>

      <CatagoryChildren
        id={id}
        childrenDisplay={childrenDisplay}
        items={items}
        lang={lang}
        catIndex={catIndex}
      />
    </div>
  );
}

const CatagoryChildren = ({ id, childrenDisplay, items, lang, catIndex }) => {
  const checkCond = (cond) => {
    if (cond === "1" || cond === 1 || cond === true) return true;
    return false;
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 12,
      },
    })
  );

  return (
    childrenDisplay && (
      <div className="ms-4">
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={(event) =>
            elementMoveLogic({
              event: event,
              items: items,
              catIndex: catIndex,
              type: "item",
              parentId: id,
            })
          }
          sensors={sensors}
          modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
        >
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {items.map((elem, index) => {
              const isSubCatCond = checkCond(elem.have_sub);

              return isSubCatCond ? (
                <MenuEditSubCatagory
                  key={elem.id + "item-container" + index}
                  catId={id}
                  subData={elem}
                  lang={lang}
                  catIndex={catIndex}
                  itemIndex={index}
                />
              ) : (
                <MenuEditItem
                  key={elem.id + "item" + index}
                  elemData={elem}
                  lang={lang}
                  elemType="item"
                />
              );
            })}
          </SortableContext>
        </DndContext>

        <AddNewElementComb id={id} type={"item"} />
      </div>
    )
  );
};
