import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default async function verifyPayment(menuId, plan) {
  const token = cookies.get("token");

  const data = { menu_id: menuId, plan_months: plan };

  console.log(token);
  try {
    const url = "https://menuapi.mubatech.com/api/admin/v1/createInvoice";
    let res = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log(res);
  } catch {
    throw new Error(
      "Failed to Verify your purcahse, Contact MunaTech if your money was transfered"
    );
  }
}
