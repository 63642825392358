import axios from "axios";

export async function isActiveApi(id, active, token) {
  try {
    const newData = { menu_id: id, active: active ? "true" : "false" };
    let url = "https://menuapi.mubatech.com/api/admin/v1/setActive";
    let data = await axios.post(url, newData, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function isActiveCatagoryApi(id, active, token) {
  try {
    const newData = { category_id: id, active: active };
    let url = `https://menuapi.mubatech.com/api/admin/v1/setCategoryActive`;
    let data = await axios.post(url, newData, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function isActiveItem(id, enable, token) {
  try {
    const newData = { item_id: id, enable: enable };
    let url = "https://menuapi.mubatech.com/api/admin/v1/setItemActive";
    let data = await axios.post(url, newData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log(data);
  } catch (error) {
    console.log(error);
  }
}

export async function isActiveSub(id, enable, token) {
  try {
    const newData = { sub_id: id, enable: enable };
    let url = "https://menuapi.mubatech.com/api/admin/v1/setSubActive";
    let data = await axios.post(url, newData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log(data);
  } catch (error) {
    console.log(error);
  }
}

export async function createMenu(myData, token) {
  try {
    let url = "https://menuapi.mubatech.com/api/admin/v1/createMenu";
    let data = await axios.post(url, myData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function createCatagory(catData, token) {
  try {
    const url = `https://menuapi.mubatech.com/api/admin/v1/createCategory`;

    const data = await axios.post(url, catData, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function createITem(itemData, token) {
  try {
    let url = "https://menuapi.mubatech.com/api/admin/v1/createItem";

    const data = await axios.post(url, itemData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    await console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function createSubtITem(itemData, token) {
  try {
    let url = "https://menuapi.mubatech.com/api/admin/v1/createSubItem";

    const data = await axios.post(url, itemData, {
      headers: { Authorization: `Bearer ${token}` },
    });

    await console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function updateData(allData, token) {
  try {
    let url = "https://menuapi.mubatech.com/api/admin/v1/update";
    let data = await axios.post(url, allData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function updateImage(imagedata, token) {
  try {
    const url = "https://menuapi.mubatech.com/api/admin/v1/updateImages";
    const data = await axios.post(url, imagedata, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(data);
  } catch (error) {
    console.log(error);
  }
}

export async function createThumbnail(itemData, token) {
  try {
    let url = "https://menuapi.mubatech.com/api/admin/v1/resize";
    const data = await axios.post(url, itemData, {
      headers: { Authorization: `Bearer ${token}` },
    });

    await console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function DeleteItem(itemid, type, token) {
  try {
    let url = "https://menuapi.mubatech.com/api/admin/v1/remove";
    let deletData = { id: itemid, type: type };
    const data = await axios.post(url, deletData, {
      headers: { Authorization: `Bearer ${token}` },
    });

    await console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function changeRank(rankInfo, token) {
  try {
    const url = "https://menuapi.mubatech.com/api/admin/v1/ranking";
    const data = await axios.post(url, rankInfo, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(data);
  } catch (error) {
    console.log(error);
  }
}

export async function userRegistration(userInfo, token) {
  try {
    const url = "https://menuapi.mubatech.com/api/admin/v1/newUser";
    const data = await axios.post(url, userInfo, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (error) {
    console.log(error);
  }
}

export async function TabletUserRegistration(userInfo, token) {
  try {
    const url = "https://menuapi.mubatech.com/api/admin/v1/newTabletUser";
    const data = await axios.post(url, userInfo, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (error) {
    console.log(error);
  }
}

// export async function getUserMenus(userId, token) {
//   let url = `https://menuapi.mubatech.com/api/admin/v1/menu/${userId}`;
//   let data = await axios.get(url, {
//     headers: { Authorization: `Bearer ${token}` },
//   });
//   return data.data;
// }

export async function addSlideShow(slidedata, token) {
  try {
    const url = "https://menuapi.mubatech.com/api/admin/v1/addSlider";
    const data = await axios.post(url, slidedata, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(data);
  } catch (error) {
    console.log(error);
  }
}
