import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",

    resources: {
      en: {
        translation: {
          menuManage: "Menus Manager",
          createaMenu: "Create a menu",
          genQR: "Generate QR",
          settings: "settings",
          changeLang: "Change Language",
          logOut: "Log out",

          languages: "Languages",
          English: "English",
          Arabic: "Arabic",
          Kurdish: "Kurdish",
          Turkish: "Turkish",

          editSlide: "Edit Slideshow",
          toMenu: "To Menu",
          editMenu: "Edit Menu",
          siteData: "Site Data",
          siteTitle: "Site Title",
          siteTitleNote: "Your Menu's URL - all lowercase",

          color: "Colors",
          primary: "Primary Color",
          secondary: "Secondary Color",
          primary_font: "Primary Font",
          secondary_font: "Secondary Font",
          normal_font: "Normal Font",

          image: "Image",

          social: "Social",
          phone: "Phone Number",
          address: "Address",
          youtube: "Youtube",
          insta: "Instagram",
          snap: "Snapchat",
          facebook: "Facebook",

          deleteSure: "ARE YOU SURE YOU WANT TO DELETE THIS MENU ?",
          deleteInsertTitle: "Please Enter Menu's Title to confirm Deletion",
          delete: "Delete",
          cancel: "Cancel",
          submit: "Submit",
          cancelUndo: "Cancel and Undo",
          confirmImageChange: "Confirm Image Change",
          menuManagment: "Menu Managment",
          menuActivity: "Menu Activity",
          deleteMenu: "DELETE MENU",

          slideshowEnabled: "Slideshow Enabled",
          slideshowDisabled: "Slideshow Disabled",
          addImageSlide: "Add image to Slideshow",

          backtoMenus: "Back to Menus",
          addCat: "Add Category",
          addItem: "Add Item",
          addSubItem: "Add Subitem",

          newCatagory: "Add New Catagory",
          editCatagory: "Edit Catagory",
          newItem: "Add New Item",
          editItem: "Edit Item",
          newSubItem: "Add New Subitem",
          editSub: "Edit Subitem",

          turnonitem: "turn on item",
          turnoffitem: "turn off item",
          yes: "YES",
          no: "NO",
          confirmChanges: "Confirm Changes",
          price: "Price",
          add: "ADD +",
          remove: "Remove",
          multiplePrices: "Multiple Prices",
          removeImage: "Remove Image",
          oldImage: "Old Image",
          newImage: "New Image",
          fullsize400: "Full size 400X400",
          thumbnail: "Thumbnail",
          subCategory: "Sub Category",
          notes: "Notes",
          name: "Name",
          manage: "Manage",
          edit: "Edit",
          data: "Data",

          downlaodQR: "Downlaod QR",
          centerImage: "Center Image",
          cornerDotOptions: "Corner Dot Options",
          cornerSquareOptions: "Corner Square Options",
          dotOption: "Dot Option",
          size: "Size",
          qrOpt: "QR Options",
          background: "Background",
          radius: "Radius",
          shape: "Shape",
          margin: "Margin",
          square: "Square",
          rounded: "Rounded",
          classy: "Classy",
          classyRounded: "Classy Rounded",
          dot: "Dot",
          circle: "Circle",
          extraRounded: "Extra Rounded",
          small: "Small",
          medium: "Medium",
          large: "Large",
          imageCropping: "Image Cropping",
          crop: "Crop",
          loginToAccount: "Log in to your account",

          emailAddress: "Email address",
          emailHelp: "We'll never share your email with anyone else.",
          password: "Password",
          errorMessage: "UserName or Password is wrong",
          createAccount: "Create an Account",
          username: "Username",
          confirmPass: "Confirm Password",
          register: "Register",

          noAccount: "Don't Have Account ?",
          registerHere: "Register Here",
          yesAccount: "Have Account ?",
          loginHere: "Login Here",

          slides: "Slides",
          error: "ERROR",
          editMenuData: "Edit Menu Data",

          backHome: "Back Home",
          pricing: "Pricing",
          pickPlan: "Pick a subscription for your Menu",
          monthPlan: "Monthly subscription",
          mo: "/mo",
          total: "Total",
          buyPlan: "BUY SUBSCRIPTION",
          halfYearPlan: "6 Month subscription",
          yearPlan: "Yearly subscription",

          pageNotFound: "Page not found",
          sorryNoPage: "Sorry, We couldn't find the Page you were looking for",
          goBackHome: "Go Back Home",

          email: "Email",
          fullName: "Full Name",
          changePassword: "Change Password",
          currentPassword: "Current Password",
          newPassword: "New Password",

          userData: "User Data",
          toPayment: "Pay menu fee",
          iqd: "IQD",

          FibPay: "FIB Payment for ",
          // payexpire: "Purchase window Expire in",
          transOf: "Transaction of",

          descPart1: "To<1> MubaTech </1>company for",
          descTime1m: "1 Month",
          descTime6m: "6 Monthes",
          descTime1y: "1 Year",
          descPart2: "subscription of MubaMenu",

          date: "Date",
          scan: "Scan",
          enterCode: "Enter Code",
          or: "or",
          success: "Success",
          sucMesPart0: "Successfully transferred",
          sucMesPart1: "to Muba Technology for",
          sucMesPart2: "subscription of MubaMenu",

          failed: "Failed",
          failTime: "Failed to pay in time",
          failReason:
            "If there is an issue or your money already transferred please contact Muba Technology team",
          loading: "Loading",

          menuFeesMes: "Menu fees are paid",
          callIfIssue: "In case of any problem contact mubatech",
        },
      },
      ar: {
        translation: {
          menuManage: "إدارة القوائم",
          createaMenu: "إنشاء قائمة",
          genQR: "إنشاء الباركود",
          settings: "إعدادات",
          changeLang: "تغيير اللغة",
          logOut: "تسجيل خروج",

          languages: "اللغات",
          English: "إنجليزي",
          Arabic: "عربي",
          Kurdish: "الكردية",
          Turkish: "التركية",

          editSlide: "تعديل الشرائح",
          toMenu: "إلى القائمة",
          editMenu: "تعديل القائمة",
          siteData: "بيانات الموقع",
          siteTitle: "عنوان الموقع",
          siteTitleNote: "عنوان قائمتك - باللغة الإنجليزية و بالأحرف الصغيرة",

          color: "الألوان",
          primary: "لون اساسي",
          secondary: "اللون الثانوي",
          primary_font: "الخط الأساسي",
          secondary_font: "الخط الثانوي",
          normal_font: "الخط العادي",

          image: "صورة",

          social: " وسائل التواصل",
          phone: "رقم التليفون",
          address: "عنوان",
          youtube: "يوتيوب",
          instagram: "إنستغرام",
          snapchat: "سناب شات",
          facebook: "فيسبوك",

          deleteSure: "هل أنت متأكد أنك تريد حذف هذه القائمة؟",
          deleteInsertTitle: "الرجاء إدخال عنوان القائمة لتأكيد الحذف",
          delete: "حذف",
          cancel: "إلغاء",
          submit: "إرسال",
          cancelUndo: "إلغاء والتراجع",
          confirmImageChange: "تأكيد تغيير الصورة",
          menuManagment: "إدارة القائمة",
          menuActivity: "تفعيل القائمة",
          deleteMenu: "حذف القائمة",

          slideshowEnabled: "الشرائح مفعلة",
          slideshowDisabled: "الشرائح غير مفعلة",
          addImageSlide: "إضافة صورة إلى الشرائح",

          backtoMenus: "العودة إلى القوائم",
          addCat: "إضافة فئة",
          addItem: "إضافة مادة",
          addSubItem: "إضافة مادة فرعية",

          newCatagory: "إضافة فئة جديدة",
          editCatagory: "تعديل ألفئة",
          newItem: "إضافة مادة جديدة",
          editItem: "تعديل ألمادة",
          newSubItem: "إضافة مادة فرعية جديدة",
          editSub: "تعديل ألمادة ألفرعية",

          turnonitem: "تشغيل مادة",
          turnoffitem: "إيقاف مادة",
          yes: "نعم",
          no: "لا",
          confirmChanges: "تأكيد التغييرات",
          price: "السعر",
          add: "إضافة +",
          remove: "إزالة",
          multiplePrices: "أسعار متعددة",
          removeImage: "إزالة الصورة",
          oldImage: "الصورة القديمة",
          newImage: "الصورة الجديدة",
          fullsize400: " 400x400 الحجم الكامل",
          thumbnail: "الصورة المصغرة",
          subCategory: "الفئة الفرعية",
          notes: "ملاحظات",
          name: "الاسم",
          manage: "إدارة",
          edit: "تحرير",
          data: "البيانات",

          downlaodQR: "تحميل الباركود",
          centerImage: "الصورة في الوسط",
          cornerDotOptions: "خيارات نقطة الزاوية",
          cornerSquareOptions: "خيارات مربع الزاوية",
          dotOption: "خيار النقطة",
          size: "الحجم",
          qrOpt: "خيارات الباركود",
          background: "الخلفية",
          radius: "قوس",
          shape: "الشكل",
          margin: "هامِش",
          square: "مربع",
          rounded: "مستدير",
          classy: "أنيق",
          classyRounded: "أنيق مستدير",
          dot: "نقطة",
          circle: "دائرة",
          extraRounded: "مستدير مزدوج",
          small: "صغير",
          medium: "متوسط",
          large: "كبير",
          imageCropping: "اقتصاص الصورة",
          crop: "قص",
          loginToAccount: "تسجيل الدخول إلى حسابك",

          emailAddress: "عنوان البريد الإلكتروني",
          emailHelp: "لن نقوم بمشاركة بريدك الإلكتروني مع أي شخص آخر",
          password: "كلمة المرور",
          errorMessage: "اسم المستخدم أو كلمة المرور خاطئة",
          createAccount: "إنشاء حساب",
          username: "اسم المستخدم",
          confirmPass: "تأكيد كلمة المرور",
          register: "تسجيل",

          noAccount: "ليس لديك حساب؟,",
          registerHere: "سجل هنا",
          yesAccount: "هل لديك حساب؟",
          loginHere: "قم بتسجيل الدخول هنا",

          slides: "الشرائح",
          error: "خطأ ",
          editMenuData: "تحرير بيانات القائمة",

          backHome: "العودة إلى الصفحة الرئيسية",
          pricing: "الأسعار",
          pickPlan: "اختر اشتراكًا لقائمتك",
          monthPlan: "اشتراك شهري",
          mo: "/شهر",
          total: "الإجمالي",
          buyPlan: "شراء الاشتراك",
          halfYearPlan: "اشتراك لمدة 6 أشهر",
          yearPlan: "اشتراك سنوي",

          pageNotFound: "الصفحة غير موجودة",
          sorryNoPage: "عذرًا، لا يمكننا العثور على الصفحة التي كنت تبحث عنها",
          goBackHome: "العودة إلى الصفحة الرئيسية",

          email: "البريد الإلكتروني",
          fullName: "الاسم الكامل",
          changePassword: "تغيير كلمة المرور",
          currentPassword: "كلمة المرور الحالية",
          newPassword: "كلمة المرور الجديدة",

          userData: "بيانات المستخدم",
          toPayment: "دفع رسوم القائمة",
          iqd: "د.ع",

          FibPay: "الدفع عبر FIB لـ",
          // payexpire: "تنتهي صلاحية الشراء في",
          transOf: "تحويل",
          descPart1: "إلى <1>شركة موباتيك</1> لـ",
          descTime1m: "شهر واحد",
          descTime6m: "ستة أشهر",
          descTime1y: "سنة واحدة",
          descPart2: "للاشتراك في موبامينيو",

          date: "التاريخ",
          scan: "مسح",
          enterCode: "إدخال الرمز",
          or: "أو",

          success: "نجاح",
          sucMesPart0: "تم التحويل",
          sucMesPart1: "بنجاح إلى شركة موباتيك لـ",
          sucMesPart2: "للاشتراك في موبامينيو",

          failed: "فشل",
          failTime: "فشل في الدفع قبل انتهاء الوقت",
          failReason:
            "إذا كانت هناك مشكلة في تحويل الأموال، يرجى الاتصال بفريق موباتيك",
          loading: "جار التحميل",

          menuFeesMes: "رسومأت القائمة مدفوعة",
          callIfIssue: "في حالة وجود أي مشكلة اتصل ب موباتكنولوجي",
        },
      },
    },
  });
