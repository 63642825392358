import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  showLoader,
  closeLoader,
} from "../../../../redux/updateLoader/updateLoaderSlice";

import {
  NameContainer,
  DoubleImageContainer,
  Swtiches,
  NoteContainer,
  PriceContainer,
  SubmitContainer,
} from "../sharedComponents";
import { createITem, createThumbnail } from "../../../../services/menuServices";
import Cookies from "universal-cookie";
import { closeEdit } from "../../../../redux/menuFunctions/menuFunctionsSlicer";
import { fetchMenu } from "../../../../redux/menuSlice";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const cookies = new Cookies();

export default function AdddNewItem() {
  const dispatch = useDispatch();
  const { title } = useParams();
  const { t } = useTranslation();

  let [newImage, setNewImage] = useState();
  let [isSub, setIsSub] = useState(false);
  let [isMultiPrice, setIsMultiplePrices] = useState(false);
  let [newData, setNewData] = useState({
    name: { en: "", ar: "", ku: "", tr: "" },
    note: { en: "", ar: "", ku: "", tr: "" },
    price: 0,
    prices: [],
  });

  let { menu } = useSelector((state) => state.menu);
  let { id } = useSelector((state) => state.menuFunction);
  let languages = menu.langs.map((e) => e.surname);

  async function handleSubmit(e) {
    e.preventDefault();

    dispatch(showLoader());
    const formData = new FormData();
    const imageData = new FormData();
    const token = cookies.get("token");

    let validData = {
      category_id: id,
      price: isMultiPrice ? 0 : newData.price,
      prices: isMultiPrice ? JSON.stringify(newData.prices) : [],
      have_price: isMultiPrice,
      have_sub: isSub,
      en: newData.name.en,
      ar: newData.name.ar,
      ku: newData.name.ku,
      tr: newData.name.tr,
      note_en: newData.note.en,
      note_ar: newData.note.ar,
      note_ku: newData.note.ku,
      note_tr: newData.note.tr,
      image: newImage,
    };

    Object.keys(validData).forEach((e) => {
      formData.append(e, validData[e]);
    });

    const valid = await createITem(formData, token);

    if (newImage) {
      await imageData.append("id", valid.data.id);
      await imageData.append("type", "item");
      await imageData.append("image", newImage);
      await createThumbnail(imageData, token);
    }

    dispatch(closeLoader());

    dispatch(closeEdit());
    dispatch(fetchMenu(title));
  }

  return (
    <form className="p-3" onSubmit={(e) => handleSubmit(e)}>
      <NameContainer
        languages={languages}
        newData={newData}
        setNewData={setNewData}
        t={t}
      />
      <hr />
      <Swtiches isSub={isSub} setIsSub={setIsSub} t={t} />
      <hr />

      {isSub ? null : (
        <React.Fragment>
          <NoteContainer
            languages={languages}
            newData={newData}
            setNewData={setNewData}
            t={t}
          />
          <hr />
          <DoubleImageContainer
            newImage={newImage}
            setNewImage={setNewImage}
            t={t}
          />
          <hr />
          <PriceContainer
            newData={newData}
            setNewData={setNewData}
            languages={languages}
            isMultiPrice={isMultiPrice}
            setIsMultiplePrices={setIsMultiplePrices}
            t={t}
          />
          <hr />
        </React.Fragment>
      )}

      <SubmitContainer t={t} />
    </form>
  );
}
