import Cookies from "universal-cookie";
import {
  isActiveItem,
  isActiveSub,
  isActiveCatagoryApi,
} from "../../../services/menuServices";

const cookies = new Cookies();

export default function handleElemEnable(elemType, elemId, elemCond) {
  let token = cookies.get("token");

  if (elemType === "item") {
    isActiveItem(elemId, elemCond, token);
  }
  if (elemType === "sub") {
    isActiveSub(elemId, elemCond, token);
  }
  if (elemType === "cat") {
    isActiveCatagoryApi(elemId, elemCond, token);
  }
}
