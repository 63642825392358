import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import MainPage from "./MainPage";
import EditMenu from "./pages/EditMenu";
import { useSelector } from "react-redux";
import Payment from "./pages/Payment";
import MissingPage from "./pages/missingPage";
import UserData from "./pages/userData";
import "bootstrap-icons/font/bootstrap-icons.css";

function App() {
  const { screenScroll } = useSelector((store) => store.screenSlice);

  return (
    <div className="h-100" style={{ overflow: screenScroll ? "" : "hidden" }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />}></Route>
          <Route path="/edit/:title" element={<EditMenu />}></Route>
          <Route path="/payment/:title" element={<Payment />}></Route>
          <Route path="/user" element={<UserData />}></Route>
          <Route path="/*" element={<MissingPage />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
