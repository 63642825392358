import { useTranslation, Trans } from "react-i18next";
import goBackArrow from "../images/goBackArrow.svg";
import PageHeader from "./pageHeader";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import getFibToken from "../services/fib/getFibToken";
import createPayment from "../services/fib/createPayment";
import checkPaymentStatus from "../services/fib/checkPaymentStatus";
import verifyPayment from "../services/fib/verifyPayment";
import { useDispatch, useSelector } from "react-redux";
import {
  setScrollOff,
  setScrollOn,
} from "../redux/screenScroll/screenScroolSlice";
import { getMenus } from "../redux/getMenus/getMenus";
import Cookies from "universal-cookie";
import { t } from "i18next";
const cookies = new Cookies();

export default function Payment() {
  const { allMenu, allMenuSuccess } = useSelector((store) => store.userMenus);
  const { t, i18n } = useTranslation();
  const { title } = useParams();
  const dispatch = useDispatch();
  const userId = cookies.get("userID");

  const isrtl = i18n.dir() === "rtl" ? true : false;

  useEffect(() => {
    if (allMenuSuccess === false) {
      dispatch(getMenus(userId));
    }
  }, []);

  return (
    <div className="h-100 d-flex flex-column">
      <Header t={t} />

      <div className="flex-grow-1">
        {allMenuSuccess === true ? (
          <PaymentBody
            allMenu={allMenu}
            t={t}
            isrtl={isrtl}
            title={title}
            dispatch={dispatch}
          />
        ) : null}
      </div>

      <PaymenFooter isrtl={isrtl} />
    </div>
  );
}

function PaymentBody({ allMenu, t, isrtl, title, dispatch }) {
  const currentMenu = allMenu.find((e) => e.title === title);

  const isActive =
    currentMenu.active === 1 || currentMenu.active === true ? true : false;

  const localPaymentData = localStorage.getItem("paymentData");

  const [showPaymentScreen, setShowPaymentScreen] = useState(
    localPaymentData ? true : false
  );

  const [paymentData, setPaymentData] = useState(JSON.parse(localPaymentData));

  useEffect(() => {
    if (showPaymentScreen === true) {
      dispatch(setScrollOff());
    } else {
      dispatch(setScrollOn());
    }
  }, [showPaymentScreen]);

  function clearLocalStorage() {
    localStorage.removeItem("paymentData");
  }

  function handleSetPaymentData(total) {
    const stringAmmount = `${total}.00 `;
    setShowPaymentScreen(true);

    getFibToken().then((tokRes) => {
      createPayment(tokRes, stringAmmount).then((payRes) => {
        const resData = { total: total, ...payRes };

        localStorage.setItem("paymentData", JSON.stringify(resData));

        setPaymentData(resData);
      });
    });
  }

  return isActive ? (
    <MenuisActive t={t} />
  ) : (
    <React.Fragment>
      <div className="pricing-header p-3 pb-md-4 mx-auto text-center container">
        <h1 className="display-4 fw-normal text-body-emphasis">
          {t("pricing")}
        </h1>
        <p className="fs-5 text-body-secondary">{t("pickPlan")}</p>
      </div>
      <div>
        <section className="container">
          <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="card mb-4 rounded-3 shadow-sm ">
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal">{t("monthPlan")}</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title mb-2">
                    {t("iqd")} 50
                    <small className="text-body-secondary fw-light">
                      {t("mo")}
                    </small>
                  </h1>
                  <p className="fs-2">
                    <s>
                      <small className="text-muted fw-light">‎</small>
                    </s>
                  </p>
                  <hr />
                  <p className="fs-4 mb-3">
                    {t("total")} - {t("iqd")} 50{" "}
                  </p>
                  <button
                    type="button"
                    className="w-100 btn btn-lg btn-outline-primary"
                    onClick={() => {
                      handleSetPaymentData(50);
                    }}
                  >
                    {t("buyPlan")}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="card mb-4 rounded-3 shadow-sm">
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal">{t("halfYearPlan")}</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title mb-2">
                    {t("iqd")} 40
                    <small className="text-body-secondary fw-light">
                      {t("mo")}
                    </small>
                  </h1>
                  <p className="fs-2">
                    <s>
                      <small className="text-muted fw-light">
                        {t("iqd")} 50
                        {t("mo")}
                      </small>
                    </s>
                  </p>

                  <hr />
                  <p className="fs-4 mb-3">
                    {t("total")} - {t("iqd")} 240{" "}
                  </p>
                  <button
                    type="button"
                    className="w-100 btn btn-lg btn-primary"
                    onClick={() => {
                      handleSetPaymentData(240);
                    }}
                  >
                    {t("buyPlan")}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-12">
              <div className="card mb-4 rounded-3 shadow-sm border-primary">
                <div className="card-header py-3 text-bg-primary border-primary">
                  <h4 className="my-0 fw-normal">{t("yearPlan")}</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title mb-2">
                    {t("iqd")} 30
                    <small className="text-body-secondary fw-light">
                      {t("mo")}
                    </small>
                  </h1>
                  <p className="fs-2">
                    <s>
                      <small className="text-muted fw-light">
                        {t("iqd")} 50
                        {t("mo")}
                      </small>
                    </s>
                  </p>

                  <hr />
                  <p className="fs-4 mb-3">
                    {t("total")} - {t("iqd")} 360
                  </p>
                  <button
                    type="button"
                    className="w-100 btn btn-lg btn-primary"
                    onClick={() => {
                      handleSetPaymentData(360);
                    }}
                  >
                    {t("buyPlan")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {paymentData && showPaymentScreen ? (
        <PaymentDoneScreen
          title={title}
          paymentData={paymentData}
          setPaymentData={setPaymentData}
          setShowPaymentScreen={setShowPaymentScreen}
          t={t}
          isrtl={isrtl}
          clearLocalStorage={clearLocalStorage}
        />
      ) : showPaymentScreen ? (
        <PayMentScreenLoading t={t} />
      ) : null}
    </React.Fragment>
  );
}

function Header({ t }) {
  return (
    <div>
      <PageHeader />

      <div className="bg-light border-bottom">
        <div className="container py-2 d-flex justify-content-between align-items-center">
          <a
            href="/"
            className="d-flex align-items-center link-body-emphasis text-decoration-none"
          >
            <img
              src={goBackArrow}
              className="mx-sm-0 mx-2"
              style={{ height: "12px" }}
              alt="<"
            />

            <span className="ms-2 fs-4 d-sm-block d-none ">
              {t("backHome")}
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}

function PaymentDoneScreen({
  title,
  paymentData,
  setPaymentData,
  setShowPaymentScreen,
  t,
  isrtl,
  clearLocalStorage,
}) {
  const [status, setStatus] = useState();

  const [boardCond, setBoardCond] = useState({
    stat: false,
    cond: "",
  });

  const qrImg = new Image();
  qrImg.src = paymentData.qrCode;

  let dateLenght = "ERROR";

  const today = new Date();
  const endDay = new Date();

  const navigate = useNavigate();

  if (paymentData.total === 360) {
    endDay.setFullYear(new Date().getFullYear() + 1);
    dateLenght = "descTime1y";
  }
  if (paymentData.total === 240) {
    endDay.setMonth(new Date().getMonth() + 6);
    dateLenght = "descTime6m";
  }
  if (paymentData.total === 50) {
    endDay.setMonth(new Date().getMonth() + 1);
    dateLenght = "descTime1m";
  }

  const endDayString = endDay.toLocaleDateString();
  const todayString = today.toLocaleDateString();

  function closeScreen() {
    setShowPaymentScreen(false);
    setPaymentData();
    clearLocalStorage();
  }

  // function numToTime(num) {
  //   let minutes = Math.floor(num / 60);
  //   let seconds = Math.floor((num % 3600) % 60);

  //   if (minutes < 10) minutes = `0${minutes}`;
  //   if (seconds < 10) seconds = `0${seconds}`;

  //   return `${minutes}:${seconds}`;
  // }

  function verifyPurchase() {
    let planType;

    switch (paymentData.total) {
      case 50:
        planType = 1;
        break;
      case 240:
        planType = 6;
        break;
      case 360:
        planType = 12;
        break;
      default:
        throw new Error("Incorrect Plant Type");
    }
    verifyPayment(title, planType);
  }

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    if (status === "PAID") {
      verifyPurchase().then(() => {
        clearLocalStorage();
        setBoardCond({
          stat: true,
          cond: "success",
        });

        const exitTimer = setTimeout(() => navigate("/"), 3000);
        return () => clearTimeout(exitTimer);
      });
    } else if (status !== "PAID") {
      const interval = setInterval(() => {
        getFibToken().then((tokRes) => {
          checkPaymentStatus(paymentData.paymentId, tokRes).then((res) =>
            setStatus(res.status)
          );
        });
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [status, paymentData]);

  return (
    <div className="position-fixed top-0 bottom-0 left-0 right-0 w-100 h-100 bg-black bg-opacity-25 d-flex overflow-auto p-2">
      <div className="m-auto rounded-3 overflow-hidden">
        <div style={{ background: "#4BB49E" }}>
          <h5
            className="text-center text-white mb-0 p-3"
            dir={isrtl ? "rtl" : "ltr"}
          >
            {t("FibPay")}
            {title}
          </h5>
        </div>
        <div className="bg-white py-3 px-5 text-center ">
          <div className="position-relative">
            <div>
              <p className="m-0 text-secondary fw-bold">{t("transOf")} </p>
              <p className="m-0 display-1 fw-bold">
                {paymentData.total}
                <span className="fs-5">{t("iqd")}</span>
              </p>

              <p
                className="m-0 text-secondary fw-bold mx-auto"
                style={{ maxWidth: "200px" }}
              >
                <Trans i18nKey={"descPart1"}>
                  To
                  <span> MubaTech </span>
                  company for
                </Trans>
                <span className="text-black"> {t(dateLenght)} </span>
                {t("descPart2")}
              </p>
            </div>

            <div className="fw-bold my-2">
              <p className="mb-0">{t("date")}</p>
              <p className="mb-0">
                {todayString} - {endDayString}
              </p>
            </div>

            <div className="my-4">
              <img className="mb-2" src={qrImg.src} alt="QR img" />
              <p className="my-1 fw-bold text-body-tertiary">{t("scan")} </p>
              <div className="row m-0 p-0 ">
                <hr className="col-5 my-auto p-0 border border-1 border-dark" />
                <span className="col m-0 p-0 fw-bold text-body-secondary">
                  {t("or")}
                </span>
                <hr className="col-5 my-auto p-0 border border-1 border-dark" />
              </div>
              <p className="my-1 fw-bold text-body-tertiary">
                {t("enterCode")}
              </p>
              <p
                className="fs-5 fw-bold mb-0"
                style={{
                  backgroundImage:
                    "linear-gradient(180deg, rgba(81,182,158,1) 0%, rgba(5,152,159,1) 100%)",
                  backgroundClip: "text",
                  color: "transparent",
                }}
              >
                {paymentData.readableCode}
              </p>
            </div>

            <ConditionBoard
              stat={boardCond.stat}
              cond={boardCond.cond}
              total={paymentData.total}
              dateLenght={dateLenght}
              todayString={todayString}
              endDayString={endDayString}
              t={t}
            />
          </div>

          <button
            className="btn btn-outline-danger w-100 fs-5"
            onClick={closeScreen}
          >
            {t("cancel")}
          </button>
        </div>
      </div>
    </div>
  );
}

function ConditionBoard({
  stat,
  cond,
  total,
  dateLenght,
  todayString,
  endDayString,
  t,
}) {
  const CondSuccess = () => (
    <div>
      <h2 className="fw-bold">{t("success")}</h2>

      <p
        className="fw-bold text-secondary mx-auto"
        style={{ maxWidth: "240px" }}
      >
        {t("sucMesPart0")}{" "}
        <span className="text-black">
          {total} {t("iqd")}
        </span>{" "}
        {t("sucMesPart1")}
        <span className="text-black"> {t(dateLenght)} </span>
        {t("sucMesPart2")}
      </p>
      <div>
        <p className="mb-0 fw-bold"> {t("date")} </p>
        <p className="mb-0 fw-bold">
          {todayString} - {endDayString}
        </p>
      </div>
    </div>
  );
  const CondTimeOut = () => (
    <div>
      <h2 className="mb-0 fw-bold">{t("failed")}</h2>
      <p className="fw-bold text-secondary small">{t("failTime")}</p>
      <p className="fw-bold mx-auto" style={{ maxWidth: "240px" }}>
        {t("failReason")}
      </p>
    </div>
  );
  return stat ? (
    <div className="position-absolute bg-white top-0 bottom-0 end-0 start-0">
      {cond === "success" && <CondSuccess />}

      {cond === "time-expire" && <CondTimeOut />}
    </div>
  ) : null;
}

function PayMentScreenLoading({ t }) {
  return (
    <div className="position-fixed top-0 bottom-0 start-0 end-0 bg-black bg-opacity-25 d-flex">
      <div className="bg-white rounded m-auto p-3 d-flex flex-column align-items-center">
        <div
          className="spinner-border text-primary"
          style={{ width: "50px", height: "50px" }}
          role="status"
        ></div>
        <p className="mb-0 mt-4">{t("loading")}</p>
      </div>
    </div>
  );
}

function MenuisActive({ t }) {
  return (
    <div className="d-flex h-100">
      <h2 className="m-auto"> {t("menuFeesMes")}</h2>;
    </div>
  );
}

function PaymenFooter({ isrtl }) {
  return (
    <div
      className="bg-light py-sm-2 px-sm-5 p-2 border-top"
      dir={isrtl ? "rtl" : "ltr"}
    >
      <p className="p-0 m-0 fw-light fst-italic">
        {t("callIfIssue")}
        <span className="fw-normal fst-normal"> 0750 808 9810</span>
      </p>
    </div>
  );
}
