import { useDispatch, useSelector } from "react-redux";
import { closeEdit } from "../../../redux/menuFunctions/menuFunctionsSlicer";
import { useEffect, useRef, useState } from "react";

import Cropper from "react-easy-crop";
import Resizer from "react-image-file-resizer";
import getCroppedImg from "../../../services/createImage";
import getCroppedImgPng from "../../../services/createImagepng";

import trashCan from "../../../images/trash-can.png";
import {
  setScrollOff,
  setScrollOn,
} from "../../../redux/screenScroll/screenScroolSlice";

export function NameContainer({ languages, newData, setNewData, t }) {
  const fullName = {
    en: "English",
    ar: "Arabic",
    tr: "Turkish",
    ku: "Kurdish",
  };

  return (
    <div>
      <h4 className="mb-4">{t("name")}</h4>
      {languages.map((lang) => {
        return (
          <div key={"name" + lang} className="mb-3">
            <label className="form-label ms-2" id="basic-addon1">
              {t(fullName[lang])}
            </label>
            <input
              onChange={(e) => {
                setNewData({
                  ...newData,
                  name: { ...newData.name, [lang]: e.target.value },
                });
              }}
              value={newData.name[lang]}
              type="text"
              className="form-control"
              required
            />
          </div>
        );
      })}
    </div>
  );
}

export function NameContainerEdit({
  oldData,
  languages,
  newData,
  setNewData,
  t,
}) {
  const fullName = {
    en: "English",
    ar: "Arabic",
    tr: "Turkish",
    ku: "Kurdish",
  };

  return (
    <div>
      <h4 className="mb-4">{t("name")}</h4>
      {languages.map((lang) => {
        return (
          <div key={"name" + lang} className="mb-3">
            <label className="form-label" id="basic-addon1">
              {t(fullName[lang])}
            </label>
            <input
              onChange={(e) => {
                setNewData({
                  ...newData,
                  name: { ...newData.name, [lang]: e.target.value },
                });
              }}
              value={newData.name[lang]}
              placeholder={oldData.name[lang]}
              type="text"
              className="form-control"
              required
            />
          </div>
        );
      })}
    </div>
  );
}

export function NoteContainer({ languages, newData, setNewData, t }) {
  const fullName = {
    en: "English",
    ar: "Arabic",
    tr: "Turkish",
    ku: "Kurdish",
  };

  return (
    <div>
      <h4 className="mb-4">{t("notes")}</h4>
      <div>
        {languages.map((lang) => {
          return (
            <div key={"note" + lang} className="mb-3">
              <label className="form-label ms-2" id="basic-addon1">
                {t(fullName[lang])}
              </label>
              <input
                onChange={(e) => {
                  setNewData({
                    ...newData,
                    note: { ...newData.note, [lang]: e.target.value },
                  });
                }}
                value={newData.note[lang]}
                type="text"
                className="form-control"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function NoteContainerEdit({
  oldData,
  languages,
  newData,
  setNewData,
  t,
}) {
  const fullName = {
    en: "English",
    ar: "Arabic",
    tr: "Turkish",
    ku: "Kurdish",
  };

  return (
    <div>
      <h4 className="mb-4">{t("notes")}</h4>
      <div>
        {languages.map((lang) => {
          return (
            <div key={"note" + lang} className="mb-3">
              <label className="form-label" id="basic-addon1">
                {t(fullName[lang])}
              </label>
              <input
                onChange={(e) => {
                  setNewData({
                    ...newData,
                    note: { ...newData.note, [lang]: e.target.value },
                  });
                }}
                value={newData.note[lang]}
                placeholder={oldData.note[lang]}
                type="text"
                className="form-control"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function Swtiches({ isSub, setIsSub, t }) {
  return (
    <div>
      <div className="form-check form-switch">
        <label className="form-check-label" htmlFor="isSubCategory">
          {t("subCategory")}
        </label>
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id="isSubCategory"
          checked={isSub}
          onChange={(e) => setIsSub(e.target.checked)}
        />
      </div>
    </div>
  );
}

export function DoubleImageContainer({ newImage, setNewImage, t }) {
  const dispatch = useDispatch();

  const InitialData = {
    view: false,
  };

  const [ImageUrl, setImageUrl] = useState();
  const [imageToCrop, setImageToCrop] = useState(InitialData);
  const inputRef = useRef(null);

  function onFileChange(event) {
    const reader = new FileReader();
    reader.readAsDataURL(event);
    reader.addEventListener("load", () => {
      setImageUrl(reader.result);
    });
    setImageToCrop({ ...imageToCrop, view: true });
  }

  useEffect(() => {
    if (imageToCrop.view === true) {
      dispatch(setScrollOff());
    } else {
      dispatch(setScrollOn());
    }
  }, [imageToCrop]);

  return (
    <div>
      <h4 className="mb-4">{t("image")}</h4>

      <input
        type="file"
        ref={inputRef}
        className="form-control"
        accept=".jpg"
        onChange={(e) => {
          if (e.target.files[0]) {
            onFileChange(e.target.files[0]);
          }
        }}
      />
      {newImage ? (
        <div className="my-3">
          <img
            className="img-thumbnail"
            src={URL.createObjectURL(newImage)}
            alt=""
          />
          <div className="mt-3 d-flex flex-wrap gap-1">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                setNewImage();
                setImageUrl();
                inputRef.current.value = "";
              }}
            >
              {t("removeImage")}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setImageToCrop({ ...imageToCrop, view: true });
              }}
            >
              {t("edit")}
            </button>
          </div>
        </div>
      ) : null}
      <ImageCropDialogue
        imageView={imageToCrop.view}
        imageURL={ImageUrl}
        cropInit={imageToCrop.crop}
        zoomInit={imageToCrop.zoom}
        aspectInit={1 / 1}
        setImageToCrop={setImageToCrop}
        setNewImage={setNewImage}
        t={t}
      />
    </div>
  );
}

export function DoubleImage({ fullImage, thumbnail, t }) {
  return (
    <div className="my-3 d-flex flex-wrap gap-3">
      <div>
        <h6>{t("fullsize400")}</h6>
        <img
          className="img-thumbnail w-100"
          src={fullImage}
          alt=""
          style={{
            aspectRatio: "1/1",
            maxWidth: "400px",
            objectFit: "cover",
          }}
        />
      </div>
      <div>
        <h6>{t("thumbnail")}</h6>
        <img
          className="img-thumbnail"
          src={thumbnail}
          alt=""
          style={{
            width: "6rem",
            height: "6rem",
            objectFit: "cover",
          }}
        />
      </div>
    </div>
  );
}

export function SingleImage({ newImage, setNewImage, id, t }) {
  const [ImageUrl, setImageUrl] = useState();
  const [imageToCrop, setImageToCrop] = useState({ view: false });

  function onFileChange(event) {
    const reader = new FileReader();
    reader.readAsDataURL(event);
    reader.addEventListener("load", () => {
      setImageUrl(reader.result);
    });
    setImageToCrop({ ...imageToCrop, view: true });
  }

  return (
    <div>
      <h4 className="mb-4"> {t("image")} </h4>
      <div className="p-2 mx-auto bg-white rounded">
        <div class="mb-3 col-12">
          <input
            class="form-control form-control-sm"
            type="file"
            accept="image/png"
            onChange={(e) => {
              if (e.target.files[0]) {
                onFileChange(e.target.files[0]);
              }
            }}
          />
        </div>

        <div className="">
          <div className="d-flex gap-2 text-center justify-content-center">
            <div>
              {newImage ? <p className="form-label">{t("oldImage")}</p> : null}
              <img
                className="img-thumbnail"
                style={{ width: "120px", height: "120px" }}
                src={`https://menuapi.mubatech.com/images/categories/${id}.png`}
                alt="imageThumbnail"
              />
            </div>
            {!newImage ? null : (
              <div>
                <p className="form-label">{t("newImage")}</p>

                <img
                  className="img-thumbnail rounded-0 rounded-top"
                  style={{ width: "120px", height: "120px" }}
                  src={URL.createObjectURL(newImage)}
                  alt="newImageThumbnail"
                />
              </div>
            )}
          </div>

          {newImage ? (
            <div className="">
              <hr />
              <div className="d-flex rounded overflow-hidden">
                <button
                  className="w-100 btn btn-danger rounded-0"
                  type="button"
                  onClick={() => {
                    setNewImage();
                  }}
                >
                  {t("removeImage")}
                </button>
                <button
                  className="w-100 btn btn-secondary rounded-0"
                  type="button"
                  onClick={() => {
                    setImageToCrop({ ...imageToCrop, view: true });
                  }}
                >
                  {t("edit")}
                </button>
              </div>
            </div>
          ) : null}

          <ImageCropDialoguePng
            imageView={imageToCrop.view}
            imageURL={ImageUrl}
            cropInit={imageToCrop.crop}
            zoomInit={imageToCrop.zoom}
            aspectInit={1 / 1}
            setImageToCrop={setImageToCrop}
            setNewImage={setNewImage}
            t={t}
          />
        </div>
      </div>
    </div>
  );
}

export function SingleImageAdd({ newImage, setNewImage, t }) {
  const [ImageUrl, setImageUrl] = useState();
  const [imageToCrop, setImageToCrop] = useState({ view: false });

  function onFileChange(event) {
    const reader = new FileReader();
    reader.readAsDataURL(event);
    reader.addEventListener("load", () => {
      setImageUrl(reader.result);
    });
    setImageToCrop({ ...imageToCrop, view: true });
  }

  return (
    <div>
      <h4 className="mb-4"> {t("image")} </h4>
      <div className="p-2 mx-auto bg-white rounded">
        <div class="">
          <input
            class="form-control form-control-sm"
            type="file"
            accept="image/png"
            onChange={(e) => {
              if (e.target.files[0]) {
                onFileChange(e.target.files[0]);
              }
            }}
          />
        </div>

        {newImage ? (
          <div className="mt-2">
            <img
              className="img-thumbnail mx-auto"
              src={URL.createObjectURL(newImage)}
              alt="newImageThumbnail"
              style={{ width: "120px" }}
            />

            <div className="d-flex mt-2 rounded overflow-hidden">
              <button
                className="w-100 btn btn-danger rounded-0"
                type="button"
                onClick={() => {
                  setNewImage();
                }}
              >
                {t("removeImage")}
              </button>
              <button
                className="w-100 btn btn-secondary rounded-0"
                type="button"
                onClick={() => {
                  setImageToCrop({ ...imageToCrop, view: true });
                }}
              >
                {t("edit")}
              </button>
            </div>
          </div>
        ) : null}

        <ImageCropDialoguePng
          imageView={imageToCrop.view}
          imageURL={ImageUrl}
          cropInit={imageToCrop.crop}
          zoomInit={imageToCrop.zoom}
          aspectInit={1 / 1}
          setImageToCrop={setImageToCrop}
          setNewImage={setNewImage}
          t={t}
        />
      </div>
    </div>
  );
}

export function PriceContainer({
  newData,
  setNewData,
  languages,
  isMultiPrice,
  setIsMultiplePrices,
  t,
}) {
  function addNewMultiPrice() {
    setNewData({
      ...newData,
      prices: [...newData.prices, { en: "", ku: "", ar: "", tr: "", price: 0 }],
    });
  }

  function singlePriceContainer() {
    return (
      <div className="input-group my-3">
        <span className="input-group-text">{t("price")}</span>
        <input
          type="number"
          onWheel={(e) => e.target.blur()}
          className="form-control"
          value={newData.price}
          onChange={(e) => setNewData({ ...newData, price: e.target.value })}
          required
        />
      </div>
    );
  }

  function mutliPriceContainer() {
    return (
      <div>
        {newData.prices.map((multItem, itemIndex) => {
          return (
            <div
              key={"muti-price-" + itemIndex}
              className="my-3 bg-body-secondary rounded-2"
            >
              <div className="d-flex justify-content-stretch ">
                <div className="bg-body-tertiary rounded-top-2">
                  <h5 className="text-nowrap mx-3 my-1">
                    ITEM - {itemIndex + 1}
                  </h5>
                </div>

                <div className="w-100 d-flex justify-content-end m-1">
                  <button
                    type="button"
                    className="btn btn-sm btn-danger"
                    onClick={() => {
                      setNewData({
                        ...newData,
                        prices: newData.prices.filter((e, i) => {
                          return i !== itemIndex;
                        }),
                      });
                    }}
                  >
                    {t("remove")}
                  </button>
                </div>
              </div>
              <div className="bg-body-secondary p-2 rounded-bottom-2">
                {languages.map((lang) => (
                  <div
                    key={"mutli-name-" + itemIndex + lang}
                    className="input-group my-1"
                  >
                    <span className="input-group-text">{lang}</span>
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={multItem[lang]}
                      onChange={(e) => {
                        setNewData({
                          ...newData,
                          prices: [
                            ...newData.prices.slice(0, itemIndex),
                            {
                              ...newData.prices[itemIndex],
                              [lang]: e.target.value,
                            },
                            ...newData.prices.slice(itemIndex + 1),
                          ],
                        });
                      }}
                    />
                  </div>
                ))}
                <div className="input-group">
                  <span className="input-group-text">{t("price")}</span>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="form-control"
                    required
                    onChange={(e) => {
                      setNewData({
                        ...newData,
                        prices: [
                          ...newData.prices.slice(0, itemIndex),
                          {
                            ...newData.prices[itemIndex],
                            price: e.target.value,
                          },
                          ...newData.prices.slice(itemIndex + 1),
                        ],
                      });
                    }}
                    value={multItem.price}
                  />
                </div>
              </div>
            </div>
          );
        })}

        <div className="d-flex justify-content-center my-3">
          <button
            type="button"
            className="btn btn-outline-primary w-100"
            onClick={() => addNewMultiPrice()}
          >
            {t("add")}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id="isMultiPrice"
          checked={isMultiPrice}
          onChange={(e) => setIsMultiplePrices(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="isMultiPrice">
          {t("multiplePrices")}
        </label>
      </div>
      {isMultiPrice ? mutliPriceContainer() : singlePriceContainer()}
    </div>
  );
}

export function PriceContainerEdit({
  oldData,
  newData,
  setNewData,
  languages,
  isMultiPrice,
  setIsMultiplePrices,
  t,
}) {
  function addNewMultiPrice() {
    setNewData({
      ...newData,
      prices: [...newData.prices, { en: "", ku: "", ar: "", tr: "", price: 0 }],
    });
  }

  const fullName = {
    en: "English",
    ar: "Arabic",
    tr: "Turkish",
    ku: "Kurdish",
  };

  function singlePriceContainer() {
    return (
      <div className="input-group my-3">
        <span className="input-group-text">{t("price")}</span>
        <input
          type="number"
          onWheel={(e) => e.target.blur()}
          className="form-control"
          value={newData.price}
          placeholder={oldData.price}
          onChange={(e) => setNewData({ ...newData, price: e.target.value })}
          required
        />
      </div>
    );
  }

  function mutliPriceContainer() {
    return (
      <div className="">
        {newData.prices.map((multItem, itemIndex) =>
          mutlipriceItem(multItem, itemIndex)
        )}

        <div className="d-flex justify-content-center my-3">
          <button
            type="button"
            className="btn btn-outline-primary w-100 "
            onClick={() => addNewMultiPrice()}
          >
            {t("add")}
          </button>
        </div>
      </div>
    );
  }

  function mutlipriceItem(multItem, itemIndex) {
    return (
      <div className="my-3 bg-dark-subtle rounded-2 shadow-sm">
        <div className="d-flex justify-content-stretch">
          <div className="w-100 d-flex justify-content-end m-1">
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={() => {
                setNewData({
                  ...newData,
                  prices: newData.prices.filter((e, i) => {
                    return i !== itemIndex;
                  }),
                });
              }}
            >
              {t("remove")}
            </button>
          </div>
        </div>
        <div className="bg-body-secondary p-2 rounded-bottom-2">
          {languages.map((lang) => (
            <div
              key={"mutli-name-" + itemIndex + lang}
              className="input-group my-1"
            >
              <span className="input-group-text" style={{ width: "80px" }}>
                {fullName[lang]}
              </span>
              <input
                type="text"
                className="form-control"
                required
                value={multItem[lang]}
                onChange={(e) => {
                  setNewData({
                    ...newData,
                    prices: [
                      ...newData.prices.slice(0, itemIndex),
                      {
                        ...newData.prices[itemIndex],
                        [lang]: e.target.value,
                      },
                      ...newData.prices.slice(itemIndex + 1),
                    ],
                  });
                }}
              />
            </div>
          ))}
          <div className="input-group mt-2">
            <span className="input-group-text" style={{ width: "80px" }}>
              {t("price")}
            </span>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              className="form-control"
              required
              onChange={(e) => {
                setNewData({
                  ...newData,
                  prices: [
                    ...newData.prices.slice(0, itemIndex),
                    {
                      ...newData.prices[itemIndex],
                      price: e.target.value,
                    },
                    ...newData.prices.slice(itemIndex + 1),
                  ],
                });
              }}
              value={multItem.price}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id="isMultiPrice"
          checked={isMultiPrice}
          onChange={(e) => setIsMultiplePrices(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="isMultiPrice">
          {t("multiplePrices")}
        </label>
      </div>
      {isMultiPrice ? mutliPriceContainer() : singlePriceContainer()}
    </div>
  );
}

export function SubmitContainer({ t }) {
  const dispatch = useDispatch();

  return (
    <div className="d-flex justify-content-between">
      <button className="btn btn-primary">{t("confirmChanges")}</button>
      <button
        className="btn btn-danger"
        type="button"
        onClick={() => {
          dispatch(closeEdit());
        }}
      >
        {t("cancel")}
      </button>
    </div>
  );
}

export function DeleteContainer({ handleDelete, type = "", t }) {
  const [confirmScreen, setConfirmScreen] = useState(false);

  const Initial = () => (
    <button
      type="button"
      onClick={(e) => setConfirmScreen(true)}
      className="btn btn-danger w-100 d-flex gap-2 align-items-center justify-content-center"
    >
      <span>{t("delete")}</span>
      <img src={trashCan} alt="tash" style={{ width: "20px" }} />
    </button>
  );

  const Confirmation = () => (
    <div className="d-flex gap-2 align-items-center">
      <span className="h5 m-0 p-0">
        {t("delete")} {type} :
      </span>
      <button
        type="button"
        className="btn btn-sm btn-secondary"
        onClick={() => setConfirmScreen(false)}
      >
        {t("no")}
      </button>
      <button
        type="button"
        className="btn btn-sm btn-danger"
        onClick={(e) => {
          handleDelete(e);
          setConfirmScreen(false);
        }}
      >
        {t("yes")}
      </button>
    </div>
  );

  return <div>{!confirmScreen ? <Initial /> : <Confirmation />}</div>;
}

export function ActiveSwitch({ defVal, handleActiveSwitch, t }) {
  return (
    <div className="form-check form-switch my-2">
      <input
        className="form-check-input"
        role="switch"
        type="checkbox"
        defaultChecked={defVal}
        onChange={(e) => handleActiveSwitch(e.target.checked)}
      />
      <label class="form-check-label">
        {defVal ? t("turnoffitem") : t("turnonitem")}
      </label>
    </div>
  );
}

export function ChangeRank({}) {
  // FOR MOBILE DEVICES
}
export function Move({
  parentId,
  allParents = [{ id: 1, name: "thing" }],
  newData,
  setNewData,
}) {
  const { menuLang } = useSelector((store) => store.preMenuLang);
  return (
    <div className="mb-3">
      <label className="form-label" htmlFor="change-cat-select">
        Change Parent Container
      </label>
      <select
        id="change-cat-select"
        className="form-select"
        aria-label="Categories names select"
        value={newData.parent_id}
        onChange={(e) => setNewData({ ...newData, parent_id: e.target.value })}
      >
        {allParents.map((e) => (
          <option
            key={parentId + "change_par" + e.id}
            value={e.id}
            className={
              e.id === parentId ? "text-primary fw-bold bg-primary-subtle" : ""
            }
          >
            {e.name[menuLang]}
          </option>
        ))}
      </select>
    </div>
  );
}
export function Dublicate({}) {}

function ImageCropDialogue({
  imageView,
  imageURL,
  cropInit = { x: 0, y: 0 },
  zoomInit = 1,
  aspectInit,
  setImageToCrop,
  setNewImage,
  t,
}) {
  const [crop, setCrop] = useState(cropInit);
  const [zoom, setZoom] = useState(zoomInit);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  function onZoomChange(zoom) {
    setZoom(zoom);
  }
  function onCropChange(crop) {
    setCrop(crop);
  }
  function onCropComplete(croppedArea, croppedAreaPixels) {
    setCroppedAreaPixels(croppedAreaPixels);
  }

  function resizeFile(file) {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        400,
        400,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob",
        400,
        400
      );
    });
  }

  const onResizeCall = async (event) => {
    try {
      const image = await resizeFile(event);
      return image;
    } catch (err) {
      console.log(err);
    }
  };

  async function onCrop() {
    const croppedImageUrl = await getCroppedImg(imageURL, croppedAreaPixels);

    const resizedImage = await onResizeCall(croppedImageUrl);

    const imageFile = new File(
      [resizedImage],
      "name.jpg",
      { type: "image/jpeg" },
      { lastModifiedDate: new Date() }
    );

    setImageToCrop({ crop, zoom, view: false });
    setNewImage(imageFile);
  }

  function onCancel() {
    setZoom(zoomInit);
    setCrop(cropInit);
    setImageToCrop((previous) => ({ ...previous, view: false }));
  }

  return imageView ? (
    <div>
      <div
        style={{ zIndex: "100" }}
        className="overflow-y-scroll position-fixed top-0 bottom-0 start-0 end-0 bg-black bg-opacity-25"
      >
        <div
          className="bg-dark-subtle rounded mx-sm-auto mx-2 my-2"
          style={{
            maxWidth: "600px",
            minWidth: "300px",
          }}
        >
          <div className="p-2">
            <h4>{t("imageCropping")}</h4>
          </div>
          <div
            className="position-relative m-auto w-100 custom-stripes-background"
            style={{
              aspectRatio: "1/1",
            }}
          >
            <Cropper
              image={imageURL}
              zoom={zoom}
              crop={crop}
              aspect={aspectInit}
              onCropChange={onCropChange}
              onZoomChange={onZoomChange}
              onCropComplete={onCropComplete}
            />
          </div>
          <div className="p-2 ">
            <input
              type="range"
              className="form-range my-4"
              step={0.01}
              min={1}
              max={3}
              value={zoom}
              onInput={(e) => {
                onZoomChange(e.target.value);
              }}
            />
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={onCrop}
              >
                {t("crop")}
              </button>
              <button
                type="button"
                className="btn btn-light w-100"
                onClick={onCancel}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

function ImageCropDialoguePng({
  imageView,
  imageURL,
  cropInit = { x: 0, y: 0 },
  zoomInit = 1,
  aspectInit,
  setImageToCrop,
  setNewImage,
  t,
}) {
  const [crop, setCrop] = useState(cropInit);
  const [zoom, setZoom] = useState(zoomInit);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  function onZoomChange(zoom) {
    setZoom(zoom);
  }
  function onCropChange(crop) {
    setCrop(crop);
  }
  function onCropComplete(croppedArea, croppedAreaPixels) {
    setCroppedAreaPixels(croppedAreaPixels);
  }

  function resizeFile(file) {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        120,
        120,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob",
        120,
        120
      );
    });
  }

  const onResizeCall = async (event) => {
    try {
      const image = await resizeFile(event);
      return image;
    } catch (err) {
      console.log(err);
    }
  };

  async function onCrop() {
    const croppedImageUrl = await getCroppedImgPng(imageURL, croppedAreaPixels);

    const resizedImage = await onResizeCall(croppedImageUrl);

    const imageFile = new File(
      [resizedImage],
      "name.png",
      { type: "image/png" },
      { lastModifiedDate: new Date() }
    );

    setImageToCrop({ crop, zoom, view: false });
    setNewImage(imageFile);
  }

  function onCancel() {
    setZoom(zoomInit);
    setCrop(cropInit);
    setImageToCrop((previous) => ({ ...previous, view: false }));
  }

  return imageView ? (
    <div>
      <div
        style={{ zIndex: "100" }}
        className="overflow-y-scroll position-fixed top-0 bottom-0 start-0 end-0 bg-black bg-opacity-25"
      >
        <div
          className="bg-dark-subtle rounded mx-sm-auto mx-2 my-2"
          style={{
            maxWidth: "600px",
            minWidth: "300px",
          }}
        >
          <div className="p-2">
            <h4>{t("imageCropping")}</h4>
          </div>
          <div
            className="position-relative m-auto w-100 custom-stripes-background"
            style={{
              aspectRatio: "1/1",
            }}
          >
            <Cropper
              image={imageURL}
              zoom={zoom}
              crop={crop}
              aspect={aspectInit}
              onCropChange={onCropChange}
              onZoomChange={onZoomChange}
              onCropComplete={onCropComplete}
            />
          </div>
          <div className="p-2 ">
            <input
              type="range"
              className="form-range my-4"
              step={0.01}
              min={1}
              max={3}
              value={zoom}
              onInput={(e) => {
                onZoomChange(e.target.value);
              }}
            />
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={onCrop}
              >
                {t("crop")}
              </button>
              <button
                type="button"
                className="btn btn-light w-100"
                onClick={onCancel}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
