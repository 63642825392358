import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default async function getFibToken() {
  const token = cookies.get("token");

  try {
    let url = "https://menuapi.mubatech.com/api/admin/v1/fib";
    let data = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.data.access_token;
  } catch (erro) {
    throw new Error(erro);
  }
}
