import handleElemEnable from "./handelElemEnable";

export default function ActivationSwitch({ elemId, elemCond, elemType }) {
  const active = elemCond;
  const checkIfTrueCond =
    active === "1" || active === 1 || active === true ? true : false;

  return (
    <label className="menu-check-box-switch">
      <input
        className="menu-check-box"
        type="checkbox"
        defaultChecked={checkIfTrueCond}
        onChange={(e) => {
          handleElemEnable(elemType, elemId, e.target.checked);
        }}
      />
      <span className="menu-check-box-slider "></span>
    </label>
  );
}
