import Cookies from "universal-cookie";
import Menu from "../components/menu/Menu";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchMenu, reseatMenuData } from "../redux/menuSlice";
import Edit from "../components/edit/menuItems";
import { Link, useParams } from "react-router-dom";
import goBackArrow from "../images/goBackArrow.svg";
import DataEditContainer from "../components/dataEdit/DataEditContainer";
import { getMenus } from "../redux/getMenus/getMenus";
import { useTranslation } from "react-i18next";
import { setToRandLang } from "../redux/preMenuLang/preMenuLangSlice";
import PageHeader from "./pageHeader";

const cookies = new Cookies();

export default function EditMenu() {
  const { loader } = useSelector((state) => state.loader);

  const dispatch = useDispatch();
  const { allMenu, allMenuSuccess } = useSelector((store) => store.userMenus);
  const { menu, success } = useSelector((store) => store.menu);
  const { menuLang } = useSelector((store) => store.preMenuLang);

  const { title } = useParams();
  const titles = allMenuSuccess ? allMenu.map((e) => e.title) : [];
  const userId = cookies.get("userID");

  const canView = titles.includes(title) ? true : false;

  function setMenuLang(e) {
    dispatch(setToRandLang(e));
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(getMenus(userId));

      await dispatch(fetchMenu(title));
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (success === true) dispatch(setToRandLang(menu.langs[0].surname));
  }, [success]);

  // if (status === true) {
  //   return (
  //     <div>
  //       <LoadingScreen
  //         loading={true}
  //         bgColor="#f1f1f1"
  //         spinnerColor="#9ee5f8"
  //         textColor="#676767"
  //         logoSrc="/logo.png"
  //         text="Welcome To Our Menu Service"
  //       />
  //     </div>
  //   );
  // }

  return (
    <div className="position-fixed top-0 bottom-0 end-0 start-0 d-flex flex-column">
      <EditMenuHeader dispatch={dispatch} title={title} />
      {canView ? (
        success === true && allMenuSuccess === true ? (
          <div className="overflow-y-scroll h-100">
            <div className="w-100  mx-auto px-0 h-100">
              <div className="row mx-auto px-0 h-100">
                <div
                  style={{ backgroundColor: menu.colors.primary }}
                  className=" col-lg-4 col-md-12 d-none d-lg-block "
                >
                  <Menu
                    siteData={menu}
                    siteId={title}
                    lang={menuLang}
                    setLang={setMenuLang}
                  />
                </div>

                <SmallScreenMenu
                  menu={menu}
                  title={title}
                  menuLang={menuLang}
                  setMenuLang={setMenuLang}
                  success={success}
                />

                <div className="col-lg-8 col-md-12 bg-body-secondary flex-grow-1 flex-shrink-0">
                  <Edit siteData={menu} lang={menuLang} />
                </div>
              </div>

              <DataEditContainer />
            </div>

            {loader ? (
              <div
                className="position-fixed fixed-top fixed-bottom m-auto"
                style={{ background: "rgba(0, 0, 0, 0.3)" }}
              >
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <div
                    class="spinner-border text-primary"
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  ></div>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <AnimatedLoadingScreen />
        )
      ) : (
        <div>Cant Load</div>
      )}
    </div>
  );
}

function SmallScreenMenu({ menu, title, menuLang, setMenuLang, success }) {
  let [smallMenuDisplay, setSmallMenuDisplay] = useState(false);

  return success ? (
    <React.Fragment>
      <div
        className="d-block d-lg-none d-flex align-items-center py-1 border-bottom"
        style={{
          background: menu.colors.primary,
        }}
      >
        <button
          className="mx-auto btn btn-lg btn-outline-light"
          onClick={() => {
            setSmallMenuDisplay((current) => !current);
          }}
        >
          {smallMenuDisplay ? "Hide Menu" : "Show Menu"}
        </button>
      </div>
      {smallMenuDisplay ? (
        <div
          className="d-block d-lg-none"
          style={{ background: menu.colors.primary }}
        >
          <div className="col-sm-12 d-block ">
            <Menu
              siteData={menu}
              siteId={title}
              lang={menuLang}
              setLang={setMenuLang}
            />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  ) : null;
}

function AnimatedLoadingScreen() {
  function TempMenu() {
    return (
      <div className="py-2 bg-light">
        <div
          className="mb-2"
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            alignItems: "center",
          }}
        >
          <div></div>
          <div
            className="custom-gradient-background-animated rounded"
            style={{ justifySelf: "center", width: "64px", height: "65px" }}
          ></div>
          <div
            className="custom-gradient-background-animated rounded"
            style={{ justifySelf: "end", width: "120px", height: "32px" }}
          ></div>
        </div>
        <div
          className="custom-gradient-background-animated w-100 rounded"
          style={{ aspectRatio: "2/1" }}
        ></div>
        <div className="my-3 d-flex gap-4">
          {[[], [], []].map((e, i) => (
            <div key={i + "tempComp1"} className="">
              <div
                className="custom-gradient-background-animated my-4 mx-auto rounded-2"
                style={{ width: "60px", height: "60px" }}
              ></div>

              <div
                className="custom-gradient-background-animated rounded-1"
                style={{ width: "85px", height: "16px" }}
              ></div>
            </div>
          ))}
        </div>

        <div>
          {[[], [], []].map((e, i) => (
            <div
              key={i + "tempComp2"}
              className="custom-gradient-background-animated my-1 mb-3 rounded-3 p-2 d-flex justify-content-between"
            >
              <div>
                <div
                  className="custom-gradient-background-animated rounded-1 mb-2"
                  style={{ width: "120px", height: "16px" }}
                ></div>
                <div
                  className="custom-gradient-background-animated rounded-1"
                  style={{ width: "80px", height: "16px" }}
                ></div>
              </div>
              <div
                className="custom-gradient-background-animated rounded-2"
                style={{ aspectRatio: "1/1", height: "96px" }}
              ></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  function TempEdit() {
    return (
      <div className="py-2">
        <div>
          {[[], [], []].map((e, i) => (
            <div
              key={i + "tempComp3"}
              className="custom-gradient-background-animated rounded mb-3 p-2"
            >
              <div className="d-flex align-items-center gap-3">
                <div
                  className="custom-gradient-background-animated rounded"
                  style={{ width: "50px", height: "50px" }}
                ></div>
                <div
                  className="custom-gradient-background-animated rounded"
                  style={{ width: "120px", height: "20px" }}
                ></div>
              </div>
              <div className="custom-gradient-background-animated"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  function TempChange() {
    return (
      <div className="py-2">
        <div>
          {[[], []].map((e, i) => (
            <div className="mb-4" key={i + "tempComp4"}>
              <div
                className="custom-gradient-background-animated mb-2 rounded"
                style={{ width: "70px", height: "30px" }}
              ></div>
              <div>
                {[[], [], []].map((e, i) => (
                  <div
                    key={i + "tempComp5"}
                    className="custom-gradient-background-animated w-100 mb-2 rounded"
                    style={{ height: "40px" }}
                  ></div>
                ))}
              </div>
            </div>
          ))}
          <div className="d-flex justify-content-between">
            <div
              className="custom-gradient-background-animated rounded"
              style={{ width: "105px", height: "40px" }}
            ></div>
            <div
              className="custom-gradient-background-animated rounded"
              style={{ width: "75px", height: "40px" }}
            ></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-100">
      <div className="row h-100 mx-0">
        <div className="col-12 col-lg-4 bg-light">
          <TempMenu />
        </div>
        <div className="col-12 col-lg-4 bg-dark-subtle">
          <TempEdit />
        </div>
        <div className="col-12 col-lg-4 bg-light">
          <TempChange />
        </div>
      </div>
    </div>
  );
}

function EditMenuHeader({ dispatch, title }) {
  const { t } = useTranslation();
  return (
    <section>
      <PageHeader />
      <section className="p-2 bg-light border-bottom">
        <div className="container px-0 d-flex justify-content-between">
          <div className="w-100">
            <Link
              className="link-body-emphasis link-underline-opacity-0 py-2 my-auto d-flex "
              to="/"
              style={{ width: "max-content" }}
              onClick={() => {
                dispatch(reseatMenuData());
              }}
            >
              <img
                className=""
                src={goBackArrow}
                style={{ width: "1rem" }}
                alt="<"
              />
              <span className="ms-2 col ms-2 fw-bold d-none d-md-block">
                {t("backtoMenus")}
              </span>
            </Link>
          </div>

          <div className="align-self-center w-100 text-md-center text-end">
            <h2 className="fs-md-6 mb-0"> {title}</h2>
          </div>
          <div className="w-100 d-md-block d-none"></div>
        </div>
      </section>
    </section>
  );
}
