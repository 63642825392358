import { Link } from "react-router-dom";
import i18next from "i18next";

export default function MissingPage() {
  const { t } = i18next;
  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <div className="text-center">
        <h2 className="mb-0" style={{ fontSize: "5rem" }}>
          404
        </h2>
        <h2 className="h5 mb-3">{t("pageNotFound")}</h2>
        <p className="mb-4 text-body-secondary"> {t("sorryNoPage")} </p>
        <Link to="/" className="btn btn-primary rounded-1 px-5">
          {t("goBackHome")}
        </Link>
      </div>
    </div>
  );
}
