import axios from "axios";

export default async function createPayment(token, ammount) {
  const data = JSON.stringify({
    monetaryValue: {
      amount: ammount,
      currency: "IQD",
    },
    statusCallbackUrl: "",
    description: "a testing api call for mubatech integration.",
    expiresIn: "PT8H6M12.345S",
    refundableFor: "PT48H",
  });

  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://fib.stage.fib.iq/protected/v1/payments",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    data: data,
  };

  try {
    const res = await axios(config);
    return res.data;
  } catch {
    throw new Error("Issue Creating Payment");
  }
}
