import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";

import "../edit/menuItem.css";

import MenuEditCatagory from "./menuEditComps/menuEditCatagory";
import elementMoveLogic from "./menuEditComps/elementMoveLogic";
import AddNewElementComb from "./menuEditComps/addNewElementComb";

export default function MenuItems({ siteData, lang }) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 12,
      },
    })
  );

  return (
    <div className="p-3">
      <AddNewElementComb type={"cat"} />
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={(event) =>
          elementMoveLogic({
            event: event,
            items: siteData.data,
            type: "cat",
            parentId: siteData.menu_id,
          })
        }
        sensors={sensors}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      >
        <SortableContext
          items={siteData.data}
          strategy={verticalListSortingStrategy}
        >
          {siteData.data.map((e, index) => {
            return (
              <MenuEditCatagory
                key={e.id + "menuEditCat" + index}
                catIndex={index}
                allData={e}
                lang={lang}
              />
            );
          })}
        </SortableContext>
      </DndContext>
    </div>
  );
}
