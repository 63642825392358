import { useDispatch, useSelector } from "react-redux";
import {
  ActiveSwitch,
  ChangeRank,
  DeleteContainer,
  DoubleImage,
  DoubleImageContainer,
  Dublicate,
  Move,
  NameContainerEdit,
  NoteContainerEdit,
  PriceContainerEdit,
  SubmitContainer,
} from "../sharedComponents";
import { useEffect, useState } from "react";
import {
  DeleteItem,
  createThumbnail,
  isActiveSub,
  updateData,
  updateImage,
} from "../../../../services/menuServices";
import Cookies from "universal-cookie";
import {
  closeLoader,
  showLoader,
} from "../../../../redux/updateLoader/updateLoaderSlice";
import { closeEdit } from "../../../../redux/menuFunctions/menuFunctionsSlicer";
import { fetchMenu } from "../../../../redux/menuSlice";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const cookies = new Cookies();
let token = cookies.get("token");

export default function EditSub() {
  const dispatch = useDispatch();
  const { title } = useParams();
  const { t } = useTranslation();

  let { menu } = useSelector((state) => state.menu);
  let { data } = useSelector((state) => state.menuFunction);
  let languages = menu.langs.map((e) => e.surname);

  let [currentTab, setCurrentTab] = useState(0);
  let [newImage, setNewImage] = useState();
  let [isMultiPrice, setIsMultiplePrices] = useState(data.have_price);

  let [newData, setNewData] = useState({
    parent_id: data.item_id,
    name: {
      en: data.name.en,
      ar: data.name.ar,
      ku: data.name.ku,
      tr: data.name.tr,
    },
    note: {
      en: data.en,
      ar: data.ar,
      ku: data.ku,
      tr: data.tr,
    },
    price: data.price,
    prices: data.sub_price,
  });

  const oldData = {
    id: data.id,
    name: {
      en: data.name.en,
      ar: data.name.ar,
      ku: data.name.ku,
      tr: data.name.tr,
    },
    note: {
      en: data.en,
      ar: data.ar,
      ku: data.ku,
      tr: data.tr,
    },
    price: data.price,
    prices: data.sub_price,
  };

  function handleActiveSwitch(e) {
    const token = cookies.get("token");
    isActiveSub(data.id, e, token);
    // isActiveItem(id, e, token);
    // isActiveCatagoryApi(thisid, condition ? true : false, token);
  }

  useEffect(() => {
    setCurrentTab(0);
    setIsMultiplePrices(data.have_price);
    setNewImage();
    setNewData({
      parent_id: data.item_id,
      name: {
        en: data.name.en,
        ar: data.name.ar,
        ku: data.name.ku,
        tr: data.name.tr,
      },
      note: {
        en: data.en,
        ar: data.ar,
        ku: data.ku,
        tr: data.tr,
      },
      price: data.price,
      prices: data.sub_price,
    });
  }, [data]);

  async function submitData(e) {
    e.preventDefault();
    dispatch(showLoader());

    let formData = new FormData();

    const validData = {
      id: data.id,
      parent_id: newData.parent_id,
      en: newData.name.en,
      ar: newData.name.ar,
      ku: newData.name.ku,
      tr: newData.name.tr,

      note_ar: newData.note.ar,
      note_en: newData.note.en,
      note_ku: newData.note.ku,
      note_tr: newData.note.tr,

      price: newData.price,
      have_price: isMultiPrice ? "true" : "false",
      prices: JSON.stringify(newData.prices),
    };

    Object.keys(validData).forEach((objName) => {
      formData.append(objName.toString(), validData[objName]);
    });
    formData.append("type", "sub");

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    await updateData(formData, token);
    dispatch(closeLoader());

    dispatch(fetchMenu(title));
  }

  async function submitImage(e) {
    dispatch(showLoader());
    e.preventDefault();
    let imageFormData = new FormData();

    imageFormData.append("image", newImage);
    imageFormData.append("type", "sub");
    imageFormData.append("id", data.id);

    await updateImage(imageFormData, token);
    await createThumbnail(imageFormData, token);
    dispatch(closeLoader());

    dispatch(fetchMenu(title));
  }

  async function handleDelete(e) {
    e.preventDefault();
    dispatch(showLoader());

    await DeleteItem(data.id, "sub", token);

    dispatch(closeLoader());
    dispatch(closeEdit());
    dispatch(fetchMenu(title));
  }

  let myTabs = [
    <DataChange
      submitData={submitData}
      oldData={oldData}
      languages={languages}
      menu={menu}
      data={data}
      newData={newData}
      setNewData={setNewData}
      isMultiPrice={isMultiPrice}
      setIsMultiplePrices={setIsMultiplePrices}
      t={t}
    />,
    <ImageChange
      submitImage={submitImage}
      newImage={newImage}
      setNewImage={setNewImage}
      data={data}
      t={t}
    />,
    <ManageTab
      data={data}
      handleDelete={handleDelete}
      handleActiveSwitch={handleActiveSwitch}
      t={t}
    />,
  ];

  return (
    <div className="">
      <ul className="nav bg-white nav-tabs">
        <li className="nav-item">
          <button
            className={`nav-link px-2 ${
              currentTab === 0 ? "bg-dark-subtle" : ""
            }`}
            onClick={() => setCurrentTab(0)}
          >
            {t("data")}
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link px-2 ${
              currentTab === 1 ? "bg-dark-subtle" : ""
            }`}
            onClick={() => setCurrentTab(1)}
          >
            {t("image")}
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link px-2 ${
              currentTab === 2 ? "bg-dark-subtle" : ""
            }`}
            onClick={() => setCurrentTab(2)}
          >
            {t("manage")}
          </button>
        </li>
      </ul>

      {myTabs[currentTab]}
    </div>
  );
}

function DataChange({
  submitData,
  oldData,
  languages,
  menu,
  data,
  newData,
  setNewData,
  isMultiPrice,
  setIsMultiplePrices,
  t,
}) {
  const allParents = menu.data
    .find((e) => e.id === data.catId)
    .items.filter((e) => e.have_sub === 1);
  // .map((e) => ({ name: e.name, id: e.id }));

  console.log(allParents);

  return (
    <form
      className="p-3"
      onSubmit={(e) => {
        submitData(e);
      }}
    >
      <Move
        parentId={data.item_id}
        allParents={allParents}
        newData={newData}
        setNewData={setNewData}
      />
      <hr />
      <NameContainerEdit
        oldData={oldData}
        languages={languages}
        newData={newData}
        setNewData={setNewData}
        t={t}
      />
      <NoteContainerEdit
        oldData={oldData}
        languages={languages}
        newData={newData}
        setNewData={setNewData}
        t={t}
      />
      <hr />

      <PriceContainerEdit
        oldData={oldData}
        languages={languages}
        isMultiPrice={isMultiPrice}
        setIsMultiplePrices={setIsMultiplePrices}
        newData={newData}
        setNewData={setNewData}
        t={t}
      />
      <hr />
      <SubmitContainer t={t} />
    </form>
  );
}

function ImageChange({ submitImage, newImage, setNewImage, data, t }) {
  return (
    <form
      className="p-3"
      onSubmit={(e) => {
        submitImage(e);
      }}
    >
      <DoubleImageContainer
        newImage={newImage}
        setNewImage={setNewImage}
        t={t}
      />
      <DoubleImage
        fullImage={`https://menuapi.mubatech.com/images/subs/${data.id}.jpg`}
        thumbnail={`https://menuapi.mubatech.com/images/subs/${data.id}.jpg`}
        t={t}
      />
      <hr />
      <SubmitContainer t={t} />
    </form>
  );
}

function ManageTab({ data, handleDelete, handleActiveSwitch, t }) {
  return (
    <div className="p-3">
      <h4> {t("manage")} </h4>
      <hr />

      <ChangeRank />
      <ActiveSwitch
        defVal={data.enable}
        handleActiveSwitch={handleActiveSwitch}
        t={t}
      />
      <Dublicate />
      <DeleteContainer handleDelete={handleDelete} type="SUBITEM" t={t} />
    </div>
  );
}
