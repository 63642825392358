import { useSortable } from "@dnd-kit/sortable";
import ActivationSwitch from "./activationSwitch";
import GrabberComp from "./grabberComp";
import { CSS } from "@dnd-kit/utilities";
import handleElemEdit from "./handleElemEdit";
import { useRef } from "react";

export default function MenuEditItem({
  catId = false,
  elemData,
  lang,
  elemType,
}) {
  const { id, enable, name, have_price, sub_price, item_price, price } =
    elemData;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const disRef = useRef();

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      className="bg-white my-2 rounded btn btn-light d-block p-0"
      ref={setNodeRef}
      style={{ ...style, touchAction: "none" }}
    >
      <div className="d-flex align-items-center">
        <GrabberComp attributes={attributes} listeners={listeners} />

        <div
          className="w-100 align-items-center py-2 pe-3 d-flex"
          onClick={(elem) =>
            handleElemEdit({
              elem: elem,
              elemDisref: disRef,
              elemID: id,
              elemData: { ...elemData, catId: catId },
              elemType: elemType,
            })
          }
        >
          <div className="w-100 d-flex">
            <MenuEditItemImageContainer elemType={elemType} id={id} />
            <MenuEditItemInfoContainer
              name={name[lang]}
              isMultiPrice={have_price}
              prices={item_price || sub_price}
              price={price}
            />
          </div>
          <div className="d-flex justify-content-end" ref={disRef}>
            <ActivationSwitch
              elemId={id}
              elemCond={enable}
              elemType={elemType}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const MenuEditItemInfoContainer = ({ name, isMultiPrice, prices, price }) => {
  return (
    <div className="d-flex w-100 justify-content-between align-items-center px-2 ">
      <span className="mb-0">{name}</span>
      <div className="rounded overflow-hidden py-1 text-light-emphasis fw-semibold">
        <span className="bg-body-secondary p-2">
          {isMultiPrice ? getMaxAndMinPrice(prices) : price}
        </span>
        <span className="bg-dark-subtle p-2">IQD</span>
      </div>
    </div>
  );
};

const MenuEditItemImageContainer = ({ elemType, id }) => {
  function getImageURL() {
    if (elemType === "item") {
      return `https://menuapi.mubatech.com/images/items/thumbnail/${id}.jpg`;
    }
    if (elemType === "sub") {
      return `https://menuapi.mubatech.com/images/subs/thumbnail/${id}.jpg`;
    }
    return "";
  }

  return (
    <div>
      <img
        className="img-fluid"
        width={40}
        height={40}
        src={getImageURL()}
        alt={elemType + id + "img"}
      />
    </div>
  );
};

function getMaxAndMinPrice(priceArray) {
  if (priceArray.length > 0) {
    let smallest = parseFloat(priceArray[0].price);
    priceArray.forEach((e) => {
      if (parseFloat(smallest) > parseFloat(e.price)) {
        smallest = parseFloat(e.price);
      }
    });
    let biggest = parseFloat(priceArray[0].price);
    priceArray.forEach((e) => {
      if (parseFloat(biggest) < parseFloat(e.price)) {
        biggest = parseFloat(e.price);
      }
    });

    return `${smallest.toLocaleString("en-US")} 
        - 
        ${biggest.toLocaleString("en-US")} `;
  } else {
    return 0;
  }
}
