import { arrayMove } from "@dnd-kit/sortable";
import {
  reArangeItem,
  reArangeSub,
  reArangeCat,
} from "../../../redux/menuSlice";
import { store } from "../../../redux/store";
import { changeRank } from "../../../services/menuServices";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default function elementMoveLogic({
  event,
  items,
  catIndex,
  itemIndex,
  type,
  parentId,
}) {
  const token = cookies.get("token");

  const activeIndex = items.findIndex((e) => e.id === event.active.id);
  const overIndex = items.findIndex((e) => e.id === event.over.id);
  const formData = new FormData();
  const newArray = arrayMove(items, activeIndex, overIndex);
  const rankAndId = newArray.map((e, i) => ({ id: e.id, rank: i }));

  if (event.active.id !== event.over.id) {
    if (type === "item") {
      store.dispatch(reArangeItem({ catIndex, activeIndex, overIndex }));
      formData.append("type", "item");
    }
    if (type === "sub") {
      store.dispatch(
        reArangeSub({ catIndex, itemIndex, activeIndex, overIndex })
      );
      formData.append("type", "sub");
    }
    if (type === "cat") {
      store.dispatch(reArangeCat({ activeIndex, overIndex }));
      formData.append("type", "category");
    }

    formData.append("id", parentId);
    formData.append("data", JSON.stringify(rankAndId));
    changeRank(formData, token);
  }
}
