export default function GrabberComp({ attributes = {}, listeners = {} }) {
  return (
    <section
      {...attributes}
      {...listeners}
      style={{ alignSelf: "stretch", cursor: "grab" }}
    >
      <span className="px-3 d-flex align-items-center h-100 fs-4">
        <i className="bi bi-grip-vertical"></i>
      </span>
    </section>
  );
}
