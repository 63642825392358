import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import React from "react";
import Cookies from "universal-cookie";

import goBackArrow from "../images/goBackArrow.svg";
import mubaLogo from "../images/mubaLogoLight.jpg";
import PageHeader from "./pageHeader";

const cookies = new Cookies();

export default function UserData() {
  const { t } = useTranslation();

  const PlaceHolderData = {
    name: "placeholderName",
    userName: "placeholderUserName",
    email: "placeholderEmail",
  };

  const [data, setData] = useState(PlaceHolderData);

  return (
    <section className="h-100 bg-body-secondary overflow-hidden">
      <MenuHeader t={t} />
      <div className="container bg-white h-100">
        <div className="row flex-lg-nowrap">
          <div className="col mb-3">
            <div className="pt-4">
              <div className="e-profile">
                <div className="row">
                  <div className="col-12 col-sm-auto mb-3">
                    <div className="mx-auto" style={{ width: "100px" }}>
                      <div
                        className="d-flex justify-content-center align-items-center rounded"
                        style={{
                          height: "140px; background-color: rgb(233, 236, 239)",
                        }}
                      >
                        <img src={mubaLogo} alt="mubaLogo" />
                      </div>
                    </div>
                  </div>
                  <div className="col d-flex flex-column flex-sm-row justify-content-between mb-3">
                    <div className="text-center text-sm-left mb-2 mb-sm-0">
                      <h4 className="pt-sm-2 pb-1 mb-0 text-nowrap">
                        {data.name}
                      </h4>
                      <p className="mb-0">@{data.userName}</p>
                    </div>
                  </div>
                </div>
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <button href="" className="active nav-link">
                      {t("settings")}
                    </button>
                  </li>
                </ul>
                <div className="tab-content pt-3">
                  <div className="tab-pane active">
                    <form className="form">
                      <div className="row">
                        <div className="col">
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label>{t("fullName")}</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="name"
                                  placeholder={data.name}
                                  value={data.name}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label>{t("username")}</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="username"
                                  placeholder={data.userName}
                                  value={data.userName}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col">
                              <div className="form-group">
                                <label>{t("email")}</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder={data.email}
                                  value={data.email}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-12 col-sm-6 mb-3">
                          <div className="mb-2">
                            <b>{t("changePassword")}</b>
                          </div>
                          <div className="row mb-2">
                            <div className="col">
                              <div className="form-group">
                                <label>{t("currentPassword")}</label>
                                <input
                                  className="form-control"
                                  type="password"
                                  d
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col">
                              <div className="form-group">
                                <label>{t("newPassword")}</label>
                                <input
                                  className="form-control"
                                  type="password"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label>{t("confirmPass")}</label>
                                <input
                                  className="form-control"
                                  type="password"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col d-flex justify-content-end">
                          <button className="btn btn-primary" type="submit">
                            {t("confirmChanges")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function MenuHeader({ t }) {
  const headerToolTipRef = useRef(null);
  const [headerToolTipView, setHeaderToolTipView] = useState(false);

  return (
    <div>
      <PageHeader />
      <section className="py-2 border-bottom bg-light">
        <div className="container d-flex justify-content-between">
          <div className="w-100">
            <Link
              className="link-body-emphasis link-underline-opacity-0 py-2 my-auto d-flex "
              to="/"
              style={{ width: "max-content" }}
            >
              <img
                className=""
                src={goBackArrow}
                style={{ width: "1rem" }}
                alt="<"
              />
              <span className="ms-2 col ms-2 fw-bold d-none d-md-block">
                {t("backtoMenus")}
              </span>
            </Link>
          </div>

          <div className="w-100 text-center align-self-center">
            <h2 className=" fw-bold text-md-center text-end">
              {t("userData")}
            </h2>
          </div>
          <div className="w-100 d-md-block d-none "></div>
        </div>
      </section>
    </div>
  );
}
